import { runInAction } from 'mobx';
import {
  AugmentedGeneration,
  Collaborator,
  DraftProcess,
  GenericStudioProcess,
  GenericStudioProcessSchema,
  SmallProcessSchema,
  StratumnProcess,
  StratumnProcessGeneratedInfo
} from 'shared';
import { z } from 'zod';

import { DatabaseModel } from '@models/database.model';
import { GenerationModel } from '@models/generation.model';
import { ProcessModel } from '@models/process.model';

import AsyncStore from '@stores/base/async.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { TooManyRequestsError } from '@/services/errors/studio-errors';
import { UserRoles } from '@/types/process.types';
import { ApiResponseEmpty } from '@/utils/http';
import nanoID from '@/utils/nanoID';
import { parseWithZod } from '@/utils/parseZodSchema';

export interface InviteUserDTO {
  role: UserRoles;
  emails: string[];
}

export interface InviteUsersResponseDTO {
  users: Collaborator[];
}
export interface UpdateRoleDTO {
  role: UserRoles;
  userId: string;
}

export interface UpdateProcessDTO {
  password?: string;
  is_public?: boolean;
  name?: string;
  image?: string;
}

export interface FetchProcessOptions {
  password?: string;
}

export interface CreateProcessDto {
  name: string;
  draft: boolean;
  template: string;
}

export class ProcessStore extends AsyncStore<ProcessModel> {
  private fetchingProcesses: Set<string> = new Set();

  constructor(rootStore: RootStore) {
    super(rootStore, ProcessModel, 'process');
  }

  public async createNewProcess(createProcessDto: CreateProcessDto) {
    const { name, draft } = createProcessDto;

    const newProcess = new ProcessModel(
      this,
      nanoID(),
      name,
      false,
      '',
      false,
      { can_edit: true, can_view: true, can_delete: true, can_leave: true },
      [],
      false,
      new Date().toISOString(),
      new Date().toISOString(),
      '',
      draft,
      undefined,
      undefined,
      undefined
    );

    const dto = {
      name: newProcess.getName(),
      id: newProcess.id,
      draft: newProcess.isDraft()
    };

    const data: ProcessModel = await this.createNew(dto);
    return data;
  }

  public async getorFetchFullProcess(
    id: ProcessModel['id'],
    fetchOptions?: FetchProcessOptions
  ): Promise<ProcessModel | undefined> {
    const cachedProcess = this.get(id);
    if (cachedProcess && !cachedProcess.isSmall()) return cachedProcess;

    let fetchedProcess: GenericStudioProcess | undefined;
    try {
      fetchedProcess = await this.fetchProcess(id, fetchOptions);
    } catch (error) {
      newError('PROCS-gjH3f', error, true);
      throw error;
    }
    if (!fetchedProcess) return;

    const newProcessAndParsedProcess = this.parseAndLoadFetchedProcess(
      fetchedProcess,
      fetchOptions
    );

    if (!newProcessAndParsedProcess) return;
    const { newProcess, parsedProcess } = newProcessAndParsedProcess;

    this.loadCommonStores(newProcess, parsedProcess);

    if (parsedProcess.draft) {
      this.loadDraftStores(newProcess, parsedProcess);
    } else {
      this.loadStratumnStores(newProcess, parsedProcess);
    }

    return newProcess;
  }

  private loadCommonStores(
    process: ProcessModel,
    parsedProcess: GenericStudioProcess
  ) {
    const databaseStore = this.rootStore.databaseStore;
    const generationStore = this.rootStore.generationStore;
    const atomStore = this.rootStore.atomStore;

    atomStore.loadAtoms(parsedProcess.atom);

    const loadedDatabases: DatabaseModel[] = [];
    for (const database of parsedProcess.databases) {
      const loadedDatabase = databaseStore.loadDatabaseToStore(database);
      if (loadedDatabase) loadedDatabases.push(loadedDatabase);
    }
    process.setDatabaseIds(loadedDatabases.map((db) => db.id));

    generationStore.totalNbOfGenerations =
      parsedProcess.generationsMetadata.numberOfGenerations;
    generationStore.getPageData(1);
  }

  private loadDraftStores = (
    process: ProcessModel,
    draftProcess: DraftProcess
  ) => {
    const workflowStore = this.rootStore.workflowStore;
    const workflowIds: string[] = [];

    for (const workflow of draftProcess.workflows) {
      const loadedWf = workflowStore.loadBaseWorkflow(workflow);
      workflowIds.push(loadedWf.id);
    }

    process.setWorkflowIds(workflowIds);
  };

  private loadStratumnStores = (
    process: ProcessModel,
    stratumnProcess: StratumnProcess
  ) => {
    const workflowStore = this.rootStore.workflowStore;
    const transitionStore = this.rootStore.transitionStore;
    const notificationTemplateStore = this.rootStore.notificationTemplateStore;

    /* ------------------------- Handle workflows ------------------------- */
    const workflowIds = workflowStore.loadWorkflows(stratumnProcess.workflows);
    process.setWorkflowIds(workflowIds);

    /* --------------------------- Handle transitions --------------------------- */
    const loadedTransitions = transitionStore.loadTransitions(
      stratumnProcess.Transition
    );
    process.setTransitionIds(
      loadedTransitions.filter((t) => t !== undefined).map((t) => t.id)
    );

    /* -------------------------- Handle notification templates ------------------*/
    const loadedNotificationTemplates =
      notificationTemplateStore.loadNotificationTemplates(
        stratumnProcess.notificationTemplates
      );
    process.setNotificationTemplateIds(
      loadedNotificationTemplates
        ?.filter((nt) => nt !== undefined)
        .map((nt) => nt.id) ?? []
    );
  };

  private async fetchProcess(
    id: ProcessModel['id'],
    fetchOptions?: FetchProcessOptions
  ) {
    if (this.fetchingProcesses.has(id)) return;

    this.fetchingProcesses.add(id);

    const fetchedProcess = await this.httpWrapper
      .get<GenericStudioProcess>(`/${id}`, {
        headers: {
          'x-studio-password': fetchOptions?.password
        }
      })
      .catch((error) => {
        newError('PROCS-VYudv', error);
        this.fetchingProcesses.delete(id);
        throw error;
      });

    this.fetchingProcesses.delete(id);

    if (!fetchedProcess) {
      newError('PROCS-cYUWH', `Failed to fetch process ${id}`);
      return;
    }

    return fetchedProcess;
  }

  private parseAndLoadFetchedProcess(
    fetchedProcess: GenericStudioProcess,
    fetchOptions?: FetchProcessOptions
  ):
    | { newProcess: ProcessModel; parsedProcess: GenericStudioProcess }
    | undefined {
    const parsedProcess = parseWithZod(
      GenericStudioProcessSchema,
      fetchedProcess,
      'PROCS-veX4f'
    );

    if (!parsedProcess) return;

    const newProcess = new ProcessModel(
      this,
      parsedProcess.id,
      parsedProcess.name,
      false,
      parsedProcess.icon,
      parsedProcess.published,
      parsedProcess.permission,
      parsedProcess.collaborators,
      parsedProcess.is_public,
      parsedProcess.updatedAt,
      parsedProcess.createdAt,
      parsedProcess.image ?? '',
      parsedProcess.draft,
      fetchOptions?.password,
      parsedProcess.icon.includes('http') ? parsedProcess.icon : undefined,
      parsedProcess.deletedAt ? parsedProcess.deletedAt : undefined
    );

    this.set(parsedProcess.id, newProcess);

    return { newProcess, parsedProcess };
  }

  public async generateProcess(
    processId: ProcessModel['id']
  ): Promise<StratumnProcessGeneratedInfo | GenerationModel> {
    const cachedProcess = this.get(processId);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${processId} not found in cache while generating`
      );
      throw new Error(
        `Process ${processId} not found in cache while generating`
      );
    }

    const response = await this.httpWrapper
      .get<StratumnProcessGeneratedInfo | AugmentedGeneration>(
        `/${processId}/generate`,
        {
          headers: {
            'x-studio-password': cachedProcess.getPassword()
          }
        }
      )
      .catch((error) => {
        if (error instanceof TooManyRequestsError) {
          return Promise.reject(
            new Error(
              'You can only trigger a new generation 3 times per minute'
            )
          );
        }
        return Promise.reject(error);
      });

    if (cachedProcess.isDraft()) {
      const newGeneration =
        this.rootStore.generationStore.preprendGenerationToPage(
          response as AugmentedGeneration,
          1
        );

      if (!newGeneration) {
        return Promise.reject(new Error('Cannot load new generation to store'));
      }

      this.rootStore.generationStore.totalNbOfGenerations =
        this.rootStore.generationStore.totalNbOfGenerations + 1;

      return newGeneration;
    }

    const workflowStore = this.rootStore.workflowStore;
    for (const wfGeneratedInfo of response as StratumnProcessGeneratedInfo) {
      const cachedWf = workflowStore.get(wfGeneratedInfo.workflowId);
      if (!cachedWf) {
        newError(
          'PROCS-dLLnr',
          `Workflow ${wfGeneratedInfo.workflowId} not found in cached, could not update published_id`
        );
        continue;
      }
      cachedWf.published_id = wfGeneratedInfo.workflowPublishedId;
    }

    return response as StratumnProcessGeneratedInfo;
  }

  public async updateProcess(
    id: Maybe<ProcessModel['id']>,
    data: UpdateProcessDTO
  ): Promise<ApiResponseEmpty> {
    const response = (await this.httpWrapper
      .patch(`/${id}`, data)
      .catch((error: Error) => {
        newError('PROCS-8Kc5L', error, true, {
          customMessage: 'An error occurred during updating the process'
        });
      })) as ApiResponseEmpty;

    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-8Kc5L',
        `Process ${id} not found in cache while updating process`
      );
      return response;
    }

    if (response) {
      runInAction(() => {
        if (data.password) cachedProcess.setPassword(data.password);
        if (data.is_public) cachedProcess.setIsPublic(data.is_public);
        if (data.name) cachedProcess.setName(data.name);
        if (data.image) cachedProcess.setImage(data.image);
      });
    }
    return response;
  }

  public async deleteProcess(id: ProcessModel['id']): Promise<boolean> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${id} not found in cache while deleting process`
      );
      return false;
    }

    await this.httpWrapper.delete(`/${id}`, {
      headers: {
        'x-studio-password': cachedProcess.getPassword()
      }
    });

    return this.data.delete(id);
  }

  public async inviteUser(
    processId: string,
    emails: string[],
    role: UserRoles
  ): Promise<Maybe<Collaborator[]>> {
    const dto: InviteUserDTO = {
      emails,
      role
    };

    const cachedProcess = this.get(processId);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${processId} not found in cache while inviting users`
      );
      return;
    }

    const response = await this.httpWrapper
      .post<InviteUsersResponseDTO>(`/${processId}/invites`, dto, {
        headers: {
          'x-studio-password': cachedProcess.getPassword()
        }
      })
      .catch((error: Error) => {
        newError('PROCS-5m6gS', error, true, {
          customMessage:
            'An error occured, user is not part of Stratumn or already invited'
        });
      });

    if (response) {
      cachedProcess.setCollaborators([
        ...cachedProcess.getCollaborators(),
        ...response.users
      ]);
      return response.users;
    }
    return;
  }

  public async loadRoles(id: string): Promise<Maybe<Collaborator[]>> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-QJYgO',
        `Process ${id} not found in cache while loading roles`
      );
      return;
    }

    return await this.httpWrapper.get<Collaborator[]>(`/${id}/roles`, {
      headers: {
        'x-studio-password': cachedProcess.getPassword()
      }
    });
  }

  public async updateRole(
    id: ProcessModel['id'],
    role: UserRoles,
    userId: string
  ): Promise<void> {
    const dto: UpdateRoleDTO = {
      role,
      userId
    };
    await this.httpWrapper.put(`/${id}/roles`, dto);

    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-QJYgO',
        `Process ${id} not found in cache while updating role`
      );
      return;
    }

    cachedProcess.setCollaborators(
      cachedProcess.getCollaborators()?.map((collaborator) => {
        if (collaborator.id === userId) return { ...collaborator, role };
        else return collaborator;
      })
    );
  }

  public async deleteRole(
    id: ProcessModel['id'],
    userId: string
  ): Promise<void> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-u6gv_',
        `Process ${id} not found in cache while deleting role`
      );
      return;
    }
    await this.httpWrapper.delete(`/${id}/roles/${userId}`, {
      headers: {
        'x-studio-password': cachedProcess.getPassword()
      }
    });

    cachedProcess.setCollaborators(
      cachedProcess.getCollaborators()?.filter((collab) => collab.id !== userId)
    );
  }

  public async getAllProcesses() {
    const fetchedProcesses = await this.httpWrapper.get('/');

    const parsedSmallProcesses = parseWithZod(
      z.array(SmallProcessSchema),
      fetchedProcesses,
      'PROC-qok32n'
    );

    if (!parsedSmallProcesses) {
      return;
    }

    for (const process of parsedSmallProcesses) {
      if (this.get(process.id)) continue;

      const newProcess = new ProcessModel(
        this,
        process.id,
        process.name,
        true,
        '',
        process.published,
        process.permission,
        [],
        false,
        process.updatedAt,
        process.createdAt,
        '',
        process.draft,
        undefined,
        undefined,
        undefined
      );

      newProcess.setWorkflowIds(process.workflows.map((wf) => wf.id));

      this.set(process.id, newProcess);
    }
  }
}
