import { ChevronsUpDown } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@components/ui/popover';
import { Separator } from '@components/ui/separator';
import { Skeleton } from '@components/ui/skeleton';

import { useProcesses } from '@hooks/useProcesses';

import { ProcessModel } from '@models/process.model';

import { ParamsList } from '@/routes/routes.types';
import theme from '@/styles/theme';

export const ProcessSelector = () => {
  const navigate = useNavigate();

  const processId = useParams()[ParamsList.ProcessId] as string;
  const { loading, processes } = useProcesses();

  const process = processes.find((p) => p.id == processId);

  const changeProcess = (p: ProcessModel) => {
    if (p.getWorkflowIds() && p.getWorkflowIds().length < 1) return;
    navigate(`/${p.id}/${p.getWorkflowIds()[0]}/editor`);
  };

  if (loading) {
    return <Skeleton className="w-[100px] h-[20px]" />;
  }

  if (!process) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex flex-row w-full hover:bg-gray-100 m-1 rounded-sm pl-3 py-1 gap-x-3 items-center select-none cursor-pointer">
          <img src={process.getIconUrl()} className="w-4 h-4 rounded-full" />
          <p className="text-sm text-gray-700 font-semibold">
            {process.getName()}
          </p>
          <ChevronsUpDown color={theme.color.grey[6]} size={14} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 z-50">
        <Command loop>
          <CommandInput placeholder="Process name" />
          <Separator />
          <CommandList>
            <CommandEmpty>No processes found.</CommandEmpty>
            <CommandGroup heading="Processes">
              {processes.map((p) => (
                <CommandItem
                  key={p.id}
                  defaultChecked={processId == p.id}
                  onSelect={() => changeProcess(p)}
                >
                  <img
                    src={p.getIconUrl()}
                    className="mr-2 w-4 h-4 rounded-full"
                  />
                  {p.getName()}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
