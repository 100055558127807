import { camelCase } from 'camel-case';
import {
  Atom,
  AtomReference,
  AtomSchema,
  AtomType,
  DiscriminatedAtomData,
  DiscriminatedAtomDataSchema,
  GlobalVariableData,
  MetaInfo,
  TraceKey,
  TraceKeyMode,
  VariableInfo
} from 'shared';
import { z } from 'zod';

import { AtomModel, AtomWithTitleSchema } from '@models/atom.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';

type CreateAtomDTO = {
  id: AtomModel['id'];
  type: AtomModel['type'];
  data: unknown;
  meta_info: AtomModel['metaInfo'];
  variable_info?: AtomModel['variableInfo'];
  process_id: string;
  references: AtomModel['references'];
  referenced_by: AtomModel['referencedBy'];
  traceKey: TraceKey;
};

export default class AtomStore extends BaseStore<AtomModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, AtomModel, 'atom');
    this.store_ready = true;
  }

  public createAtom<TType extends AtomType, TAtom extends Atom<TType>>(
    atomId: AtomModel['id'],
    atomType: TType,
    data: DiscriminatedAtomData<TType>['data'],
    metaInfo: AtomModel['metaInfo'],
    processId: string,
    variableInfo?: AtomModel['variableInfo']
  ): Maybe<AtomModel<TAtom['data']>> {
    // ? we don't care about type any here here, since we are going to parse it anyway
    // debugger;

    const newAtom: DiscriminatedAtomData<TType> = {
      type: atomType,
      data: data
    };
    const parsedData = parseWithZod<object>(
      z.object({}).and(DiscriminatedAtomDataSchema),
      newAtom,
      'ATOM-ca7e3'
    );

    if (!parsedData) return;

    const dataWithTitle = parseWithZod(
      z.object({ title: z.string() }),
      data,
      'ATOM-MtFzA'
    );

    const newTraceKeyDTO: TraceKey = {
      value: dataWithTitle ? camelCase(dataWithTitle.title) : atomId,
      mode: dataWithTitle ? TraceKeyMode.Follow : TraceKeyMode.Locked
    };

    const newAtomModel = new AtomModel<TData>(
      this,
      atomId,
      newTraceKeyDTO,
      atomType,
      data,
      metaInfo,
      [],
      [],
      processId,
      variableInfo
    );

    this.set(atomId, newAtomModel);

    const dto: CreateAtomDTO = {
      id: atomId,
      type: newAtomModel.type,
      data: newAtomModel.data,
      meta_info: newAtomModel.metaInfo,
      variable_info: newAtomModel.variableInfo,
      process_id: processId,
      references: newAtomModel.references,
      referenced_by: newAtomModel.referencedBy,
      traceKey: newAtomModel.traceKey.toJSON
    };

    this.httpWrapper.post('/', dto).catch((error: Error) => {
      newError('ATOM-1fWYz', error, true);
    });

    return newAtomModel;
  }

  loadSingleAtom(atom: Atom) {
    const parsedAtom = parseWithZod(AtomSchema, atom, 'ATOM-1k3j2');

    if (!parsedAtom) return;

    const variableInfo =
      parsedAtom.variable_info == null ? undefined : parsedAtom.variable_info;

    const newAtom = new AtomModel(
      this.rootStore.atomStore,
      parsedAtom.id,
      parsedAtom.traceKey,
      parsedAtom.type,
      parsedAtom.data,
      parsedAtom.meta_info,
      parsedAtom.referenced_by,
      parsedAtom.references,
      parsedAtom.process_id,
      variableInfo
    );

    this.set(atom.id, newAtom);

    return newAtom;
  }

  public loadAtoms(atoms: Atom[]) {
    atoms.forEach((atom) => this.loadSingleAtom(atom));
  }

  getAtomById<TData>(
    id: Maybe<AtomModel['id']>,
    atomType: AtomType
  ): Maybe<AtomModel<TData>> | Error {
    if (!id) return;
    const atomModel: Maybe<AtomModel<unknown>> = this.get(id);
    if (!atomModel) {
      newError(
        'ATOM-TqjbE',
        `Variable with id ${id} not found (maybe creating it)`,
        ['local', 'staging'].includes(ENV),
        {
          errorType: 'warning',
          description: 'Cannot find an atom, maybe need to create it'
        }
      );
      return;
    }

    if (atomModel.type !== atomType) {
      return newError(
        'ATOM-h9kMS',
        `Variable with id ${id} has incorrect item type: "${atomModel.type}" instead of requested "${atomType}"`,
        true
      );
    }

    const parsedVariableData = parseWithZod(
      DiscriminatedAtomDataSchema,
      atomModel.atomData,
      'ATOM-Xk84G'
    );

    if (!parsedVariableData) return new Error();

    return atomModel as AtomModel<TData>;
  }

  public useAtom<TSource extends AtomType, TData extends DiscriminatedAtomData>(
    type: TSource,
    initialData: TData,
    sourceId: MetaInfo['source']['elementId'],
    parentId: MetaInfo['source']['parentId'],
    parentKind: MetaInfo['source']['parentKind'],
    processId: string,
    variableInfo?: VariableInfo
  ) {
    const dataVariable = this.getAtomById<TData>(sourceId, type);

    if (dataVariable instanceof Error) return;

    if (dataVariable) {
      return dataVariable;
    }

    if (!sourceId) {
      newError(
        'ATOM-Uiffp',
        'Source id is undefined when creating a new data item'
      );
      return;
    }

    const metaInfo: MetaInfo = {
      source: {
        elementId: sourceId,
        parentId,
        name: parentKind,
        parentKind
      }
    };

    const newDataVariable = this.createAtom(
      sourceId,
      type,
      initialData,
      metaInfo,
      processId,
      variableInfo
    );

    if (!newDataVariable) {
      newError('ATOM-9owUn', 'Failed to create a new data variable');
      return;
    }

    return newDataVariable;
  }

  getAtomById_Unsafe<TData>(
    id: Maybe<AtomModel['id']>
  ): Maybe<AtomModel<TData>> {
    if (!id) return;
    const dataItem: Maybe<AtomModel<unknown>> = this.get(id);
    if (!dataItem) return;

    return dataItem as AtomModel<TData>;
  }

  getAllVariables(): AtomModel<{ title: string }>[] {
    return this.toArray().filter(
      (atom) => atom.metaInfo.source.parentKind === 'variables'
    ) as AtomModel<{ title: string }>[];
  }

  getAllGlobalVariables(): AtomModel<GlobalVariableData>[] {
    return this.toArray().filter(
      (atom) => atom.metaInfo.source.parentKind === 'globalVariables'
    ) as AtomModel<GlobalVariableData>[];
  }

  getAtomReferenceTitle(
    variableRef: Maybe<AtomReference> | null
  ): Maybe<string> {
    if (!variableRef) return;
    const atom = this.getAtomById(
      variableRef.dataItemId,
      variableRef.blockType as AtomType
    );

    if (atom instanceof Error) {
      newError('VARREF-1r0a4', "Couldn't find selected variable", false);
      return;
    }

    const atomData = parseWithZod(
      AtomWithTitleSchema,
      atom?.data,
      'TITLE-1klj32'
    );

    if (!atomData) return;
    return atomData.title;
  }

  /** Gets all the referencable atoms. Currently only form fields and database are referencable, and they are both of dataType `variable`. */
  get allReferencableAtoms(): AtomModel[] {
    return this.getAllVariables();
  }

  getAllAtomsBySourceId(sourceId: string): AtomModel[] {
    return this.toArray().filter(
      (atom) => atom.metaInfo.source.elementId === sourceId
    );
  }

  public deleteAtom(id: AtomModel['id']): Promise<boolean> {
    const errorMessage = `Error while trying to delete atom with id "${id}"`;
    const atomToDelete = this.get(id);

    if (!atomToDelete) {
      newError('ATOM-yKOyQ', `${errorMessage}: not found`, true);
      return Promise.resolve(false);
    }

    const referencedAtoms = atomToDelete.references.map((referenceId) => {
      const referencedAtom = this.get(referenceId);
      if (!referencedAtom) {
        newError(
          'ATOM-0aDqX',
          `${errorMessage}: referenced Atom with id "${referenceId}" not found`,
          true
        );
        return;
      }
      return referencedAtom;
    });

    if (referencedAtoms.some((atom): atom is undefined => !atom?.id)) {
      newError(
        'ATOM-9jYdb',
        `${errorMessage}: Canceled task since some referenced atoms were not found`,
        true
      );
      return Promise.resolve(false);
    }

    (referencedAtoms as AtomModel[]).forEach((referencedAtoms) => {
      referencedAtoms.referencedBy = referencedAtoms.referencedBy.filter(
        (referencedByAtomId) => referencedByAtomId !== id
      );
    });

    return this.delete(id);
  }

  public async deleteAtomsBySourceId(sourceId: string) {
    const atomsToDelete = this.getAllAtomsBySourceId(sourceId);

    if (atomsToDelete.length === 0) {
      return;
    }

    await Promise.all(atomsToDelete.map((atom) => this.deleteAtom(atom.id)));
  }
}
