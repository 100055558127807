import { camelCase } from 'camel-case';
import { action, makeObservable, observable, reaction } from 'mobx';
import { DynamicType, UpdateCodeDTO } from 'shared';

import { MonacoError } from '@components/monaco/monacoEditor.code';

import { ActionModel } from '@models/action/action.model';
import Model, { ModelError } from '@models/base/base.model';
import { starterTypes } from '@models/code/starter.types';
import { TransitionModel } from '@models/transition.model';

import BaseStore from '@stores/base/base.store';

import { newError } from '@/services/errors/errors';
import { transpileCode } from '@/utils/typescript/transpileCode';

import { DEFAULT_TRANSPILED_CODE, EmptyFormDataType } from './constants';

export class CodeModel extends Model {
  private cachedPreCode: Maybe<string>;
  public errors: ModelError[] = [];
  constructor(
    store: BaseStore<CodeModel>,
    id: string,
    public parent: {
      type: 'action' | 'transition';
      id: ActionModel['id'] | TransitionModel['id'];
    },
    public code: string,
    public workflowId: string,
    public transpiledCode: Nullable<string>,
    public isActive: boolean
  ) {
    super(store, id, false);

    makeObservable(this, {
      code: observable,
      getPreCode: action
    });

    reaction(
      () => this.code,
      () => {
        this.setTranspiledCode();
        this.setIsActive();
        this.store.update(this.id).catch((error: Error) => {
          newError('CODE-IV1N2', error, true);
        });
      },
      { delay: 1000 }
    );
  }

  private setTranspiledCode(): void {
    this.transpiledCode = transpileCode(this.code);
  }

  // TODO: use a checkbox to enable/disable custom code
  private setIsActive(): void {
    this.isActive =
      !!this.transpiledCode &&
      camelCase(this.transpiledCode) != camelCase(DEFAULT_TRANSPILED_CODE);
  }

  getPreCode(): string {
    if (!this.cachedPreCode) this.cachedPreCode = this.computePreCode();
    return this.cachedPreCode;
  }

  private computePreCode(): string {
    const codeStore = this.store.rootStore.codeStore;
    const formDataTsType = this.computeFormDataCode();
    const actionTsType = codeStore.getActionTsType();
    const lanesTsType = codeStore.getLaneTsType();
    const traceStateTsType = codeStore.getTraceStateTsType();
    const globalStateTsType = codeStore.getGlobalStateTsType();

    const staticPreCode = this.getStaticPreCode();

    return `
${formDataTsType}

${actionTsType}

${lanesTsType}

${traceStateTsType}

${globalStateTsType}

${staticPreCode}
`;
  }

  public recomputePreCode(): void {
    this.cachedPreCode = this.computePreCode();
  }

  public getStaticPreCode(): string {
    return starterTypes;
  }

  public computeFormDataCode(): string {
    const formDndModel = this.getCurrentFormDndModel();
    if (!formDndModel) return EmptyFormDataType;

    const formDataType = formDndModel.computeFormDataTsType();
    return `type ${DynamicType.FormData} = ${formDataType}`;
  }

  public getCurrentFormDndModel() {
    if (this.parent.type !== 'action') return;
    const actionId = this.parent.id;
    return this.store.rootStore.actionStore.get(actionId)?.formDnd;
  }

  get toJSON(): UpdateCodeDTO {
    return {
      code: this.code,
      transpiled_code: this.transpiledCode,
      isActive: this.isActive
    };
  }

  get parentModel(): Maybe<ActionModel | TransitionModel> {
    if (this.parent.type === 'action') {
      return this.store.rootStore.actionStore.get(this.parent.id);
    } else if (this.parent.type === 'transition') {
      return this.store.rootStore.transitionStore.get(this.parent.id);
    }
    newError('CODE-tHGmR', 'Invalid parent type', true);
    return undefined;
  }

  public setErrors(monacoErrors: MonacoError[]) {
    let name: Maybe<string>;
    if (this.parentModel instanceof ActionModel) {
      name = `Code error in action ${this.parentModel.name}`;
    } else if (this.parentModel instanceof TransitionModel) {
      name = `Code error in transition ${this.parentModel.sourceName} -> ${this.parentModel.targetName}`;
    }
    const getLineNumber = (from: number, to: number) => {
      if (from === to) return `line ${from}`;
      return `line ${from} to line ${to}`;
    };

    const newErrors = monacoErrors.map((monacoError: MonacoError) => {
      const { message, startLineNumber, endLineNumber } = monacoError;

      return {
        name,
        message: `${message} (${getLineNumber(startLineNumber, endLineNumber)})`
      } satisfies ModelError;
    });

    console.log('newErrors: ', newErrors);
    this.errors = newErrors;
  }
}
