import { Shape } from 'bpmn-js/lib/model/Types';
import { BPMNTaskToActionType, isBpmnTask } from 'shared';

import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { ElementIdType } from '@/types/bpmn.types';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '@/types/event.types';

import { UserAction } from './shape.plexer';

export type DeleteButtonPressed = {
  callback: () => void;
  element: Shape;
};

export const deleteButtonPressed = (
  event: DeleteButtonPressed,
  rootStore: RootStore
) => {
  const type = event.element.id.split('_')[0] as ElementIdType;
  const id = event.element.id;
  const referenceModal = rootStore.modalStore.referenceModal;

  switch (type) {
    case ElementIdType.Activity: {
      const actionStore = rootStore.actionStore;
      const action = actionStore.get(id);

      if (action == undefined) {
        newError(
          'ACTIO-wqGOD',
          `Action with id ${id} not found in store`,
          true
        );
        return;
      }

      if (action.isDeletable) {
        event.callback();
      } else {
        referenceModal.open('action', id, 'referencedBy', 'action');
      }

      break;
    }
    case ElementIdType.Event:
      event.callback();
      break;
    case ElementIdType.StartEvent:
      event.callback();
      break;
    case ElementIdType.Gateway:
      event.callback();
      break;
    case ElementIdType.Transition:
      event.callback();
      break;
    case ElementIdType.Lane:
      event.callback();
      break;
    case ElementIdType.Workflow:
      newError('WORKF-SWdvW', 'You cannot delete a workflow', true);
      break;
    default:
      newError(
        'STUD-0qhZd',
        `Type ${type} with id ${id} cannot be deleted`,
        true
      );
      break;
  }
};

export const onActionCreated = async (
  event: CreateShapeEvent,
  workflow: WorkflowModel,
  rootStore: RootStore
) => {
  const rawShapeId = event.context.shape?.id;
  const rawShapeType = event.context.shape?.type;
  const rawWorflowId = event.context.parent?.id;
  if (!rawShapeId || !rawWorflowId || !isBpmnTask(rawShapeType)) return false;

  const actionType = BPMNTaskToActionType[rawShapeType];

  const shapeId = rawShapeId;

  const actionStore = rootStore.actionStore;
  const res = await actionStore.createNewAction(
    shapeId,
    actionType,
    workflow.id
  );

  if (res) {
    return true;
  } else {
    return false;
  }
};

export const onActionDeleted = async (
  event: DeleteShapeEvent,
  workflowModel: WorkflowModel,
  rootStore: RootStore
) => {
  const shapeId = event.context.shape?.id;
  if (!shapeId) return false;

  const actionId = shapeId;
  return await rootStore.actionStore.deleteById(actionId);
};

export const actionsFunctionPlexer: Record<
  UserAction,
  (
    event: ShapeEvents,
    workflowModel: WorkflowModel,
    rootStore: RootStore
  ) => Promise<boolean>
> = {
  create: onActionCreated,
  delete: onActionDeleted
};
