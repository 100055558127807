import { makeObservable, observable, reaction } from 'mobx';
import { AugmentedGeneration, AugmentedGenerationSchema } from 'shared';

import { GenerationModel } from '@models/generation.model';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export default class GenerationStore extends BaseStore<GenerationModel> {
  private totalNumberOfGenerations: number = 0;
  private paginatedData: Map<number, string[]> = new Map();
  private fetchingPages: Set<number> = new Set();
  private fetchingGenerations: Set<string> = new Set();
  private itemsPerPage: number = 7;
  private numberOfPages: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore, GenerationModel, 'generations');

    makeObservable<
      GenerationStore,
      | 'paginatedData'
      | 'totalNumberOfGenerations'
      | 'fetchingPages'
      | 'fetchingGenerations'
    >(this, {
      paginatedData: observable,
      totalNumberOfGenerations: observable,
      fetchingPages: observable,
      fetchingGenerations: observable
    });

    reaction(
      () => this.totalNumberOfGenerations,
      () => {
        this.numberOfPages = Math.ceil(
          this.totalNumberOfGenerations / this.itemsPerPage
        );
      }
    );
  }

  /* ------------------------ Class properties getters ------------------------ */
  public get totalNbOfGenerations(): number {
    return this.totalNumberOfGenerations;
  }

  public set totalNbOfGenerations(value: number) {
    this.totalNumberOfGenerations = value;
  }

  public get totalNumberOfPages(): number {
    return this.numberOfPages;
  }

  public get fetchingPagesSet(): Set<number> {
    return this.fetchingPages;
  }

  public getPageData(page: number, take?: number): GenerationModel[] {
    if (!this.paginatedData.has(page) && !this.fetchingPages.has(page)) {
      this.fetchPage(page, take);
    }
    return this.getCachedPageData(page);
  }

  public getOrPoll(id: string): GenerationModel | undefined {
    const cachedGeneration = this.get(id);
    if (cachedGeneration) return cachedGeneration;

    this.pollGeneration(id).then((polledGeneration) => {
      if (!polledGeneration) return;

      this.parseAndLoadFetchedGenerations([polledGeneration]);
    });
    return;
  }

  public get NbOfItemsPerPage(): number {
    return this.itemsPerPage;
  }

  /* ------------------------ Class properties getters ------------------------ */
  public preprendGenerationToPage(
    augmentedGeneration: AugmentedGeneration,
    page: number
  ) {
    const loadedGenerations = this.parseAndLoadFetchedGenerations([
      augmentedGeneration
    ]);

    if (loadedGenerations.length <= 0) return;

    const newGeneration = loadedGenerations[0];

    this.handlePageOverflow(page, newGeneration.id);

    return newGeneration;
  }

  /* --------------------------------- Helpers -------------------------------- */
  public updatePreviouslyDeployedGenerationsInCache(
    currentlyDeployedId: string
  ) {
    const currentlyDeployedGenerationsCached = this.toArray().filter(
      (generation) =>
        generation.getCurrentlyDeployed() &&
        generation.id !== currentlyDeployedId
    );

    for (const generation of currentlyDeployedGenerationsCached) {
      generation.setCurrentlyDeployed(false);
    }
  }

  public async pollGeneration(
    id: string
  ): Promise<AugmentedGeneration | undefined> {
    if (this.fetchingGenerations.has(id)) return;

    this.fetchingGenerations.add(id);

    const polledGenerationDTO = await this.httpWrapper
      .get<AugmentedGeneration>(`/${id}`)
      .catch((error) => {
        newError('GNST-5LsZp', error);
      });

    this.fetchingGenerations.delete(id);

    if (!polledGenerationDTO) {
      newError('GNST-9GPnC', `Failed to poll generation ${id}`);
      return;
    }

    return polledGenerationDTO;
  }

  private async fetchPage(page: number, take?: number) {
    this.fetchingPages.add(page);

    const fetchedGenerations = await this.httpWrapper.get<
      AugmentedGeneration[]
    >(`?page=${page}&take=${take ?? this.itemsPerPage}`);

    if (!fetchedGenerations) {
      newError('GNST-4PC08', `Failed to fetch page ${page} of generations`);
      return;
    }

    const loadedGenerations =
      this.parseAndLoadFetchedGenerations(fetchedGenerations);

    //! We assume the backend sends the generations sorted by createdAt
    this.paginatedData.set(
      page,
      loadedGenerations.map((generation) => generation.id)
    );

    this.fetchingPages.delete(page);
  }

  private getCachedPageData(page: number): GenerationModel[] {
    return (
      this.paginatedData.get(page)?.map((id) => this.get(id)) || []
    ).filter((generation) => generation !== undefined);
  }

  private hasSpaceInPage(pageData: string[]): boolean {
    return pageData.length < this.itemsPerPage;
  }

  private isLastPage(page: number): boolean {
    return page === this.totalNumberOfPages;
  }

  private createNewPage(newPage: number, generationId: string): void {
    this.paginatedData.set(newPage, [generationId]);
  }

  private isNotCached(page: number): boolean {
    return !this.paginatedData.has(page);
  }

  private moveGenerationBetweenPages(
    currentPageData: string[],
    incomingGenerationId: string
  ): string {
    const outgoingGenerationId = currentPageData.pop()!;

    currentPageData.unshift(incomingGenerationId);

    return outgoingGenerationId;
  }

  private invalidateCacheFromPage(startPage: number): void {
    for (let page = startPage; page <= this.totalNumberOfPages; page++) {
      this.paginatedData.delete(page);
    }
  }

  private handlePageOverflow(
    startPage: number,
    overflowGenerationId: string
  ): void {
    let currentPage = startPage;

    while (currentPage <= this.totalNumberOfPages) {
      if (this.isNotCached(currentPage)) {
        this.invalidateCacheFromPage(currentPage);
        return;
      }

      const currentPageData = this.paginatedData.get(currentPage);

      if (!currentPageData) return;

      if (this.hasSpaceInPage(currentPageData)) {
        currentPageData.unshift(overflowGenerationId);
        return;
      }

      overflowGenerationId = this.moveGenerationBetweenPages(
        currentPageData,
        overflowGenerationId
      );

      if (this.isLastPage(currentPage)) {
        this.createNewPage(currentPage + 1, overflowGenerationId);
      }

      currentPage++;
    }
  }

  private parseAndLoadFetchedGenerations(
    augmentedGenerations: AugmentedGeneration[]
  ): GenerationModel[] {
    const loadedGenerations: GenerationModel[] = [];

    for (const augmentedGenerationDTO of augmentedGenerations) {
      const parsedGenerationData = parseWithZod(
        AugmentedGenerationSchema,
        augmentedGenerationDTO,
        'GNST-DzRG6'
      );
      if (!parsedGenerationData) continue;

      const newGeneration = new GenerationModel(
        this,
        parsedGenerationData.id,
        parsedGenerationData.createdBy,
        parsedGenerationData.createdAt,
        parsedGenerationData.job_id,
        parsedGenerationData.process_id,
        parsedGenerationData.source,
        parsedGenerationData.pods,
        parsedGenerationData.currentlyDeployed,
        parsedGenerationData.timedOutAt
      );

      this.set(parsedGenerationData.id, newGeneration);

      loadedGenerations.push(newGeneration);
    }

    return loadedGenerations;
  }
}
