import styled from 'styled-components';

export const EmptyDatabaseRoot = styled.div`
  display: flex;
  justify-content: start;
`;

export const EmptyDatabaseText = styled.h2`
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.color.grey[4]};
`;

export const GridBody = styled.div``;

export const DatabasesContainer = styled.div`
  width: 100%;
  height: 100%;
`;
