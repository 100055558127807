import { DynamicType, GlobalVariableData, LoadedCodeData } from 'shared';

import { AtomModel } from '@models/atom.model';
import { CodeModel } from '@models/code/code.model';
import {
  EmptyGlobalVariablesType,
  EmptyLaneType
} from '@models/code/constants';
import { starterTypes } from '@models/code/starter.types';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { arrayToUnionType } from '@/utils/typescript/arrayToUnionType';
import { getCustomTypeName } from '@library/globalVariables/blocks/globalVariableBlock/globalVariable.utils';

export default class CodeStore extends BaseStore<CodeModel> {
  public lastCodeModel: Maybe<CodeModel>;
  constructor(rootStore: RootStore) {
    super(rootStore, CodeModel, 'customCode');
    this.store_ready = true;
  }

  public addLoadedCodeToStore(
    codeData: LoadedCodeData,
    parent: CodeModel['parent'],
    workflowId: string
  ) {
    const newCodeModel = new CodeModel(
      this,
      codeData.id,
      parent,
      codeData.code,
      workflowId,
      codeData.transpiled_code,
      codeData.isActive
    );

    this.set(newCodeModel.id, newCodeModel);
    return newCodeModel;
  }

  setLastCodeModel(lastCodeModel: Maybe<CodeModel>): void {
    if (this.lastCodeModel?.id === lastCodeModel?.id) return;
    this.lastCodeModel = lastCodeModel;
  }

  public getActionTsType(): string {
    return this.rootStore.actionStore.getActionsTypeScriptType();
  }

  public computePreCode(): string {
    const formDataTsType = this.lastCodeModel?.computeFormDataCode();
    const actionTsType = this.getActionTsType();
    const lanesTsType = this.getLaneTsType();
    const traceStateTsType = this.getTraceStateTsType();
    const globalStateTsType = this.getGlobalStateTsType();

    const staticPreCode = this.getStaticPreCode();

    return `
${formDataTsType}

${actionTsType}

${lanesTsType}

${traceStateTsType}

${globalStateTsType}

${staticPreCode}
`;
  }

  public getStaticPreCode(): string {
    return starterTypes;
  }

  public getLaneTsType(): string {
    const workflowId = this.lastCodeModel?.workflowId;

    if (!workflowId) return EmptyGlobalVariablesType;

    const workflow = this.rootStore.workflowStore.get(workflowId);

    if (!workflow) return EmptyGlobalVariablesType;

    const bpmnModel = workflow?.bpmn;
    if (!bpmnModel) return EmptyLaneType;

    const laneNames = bpmnModel.getLaneNames();
    if (!laneNames) return EmptyLaneType;

    return `type ${DynamicType.Lanes} = ${arrayToUnionType(laneNames)};`;
  }

  public getTraceStateTsType(): string {
    const allActions = this.rootStore.actionStore.getAllActions();
    const actionTypes = allActions.map((action) => {
      return `  ${action.traceKey.value}?: ${
        action.formDnd?.computeFormDataTsType() ?? 'never'
      }`;
    });

    return `type ${DynamicType.StateData} = {\n${actionTypes.join('\n')}\n};`;
  }

  public getGlobalStateTsType(): string {
    const workflowId = this.lastCodeModel?.workflowId;

    if (!workflowId) return EmptyGlobalVariablesType;

    const workflow = this.rootStore.workflowStore.get(workflowId);

    if (!workflow) return EmptyGlobalVariablesType;

    const globalVariableAtoms = workflow.globalVariableAtoms;
    const customTypesDeclarations: string[] = [];

    const getVariableLine = (variable: AtomModel<GlobalVariableData>) => {
      let tsType = '';
      if (variable.data.variableType.selectedKind === 'custom') {
        const customType = variable.data.variableType.customType;
        const customTypeName = getCustomTypeName(customType);

        if (customTypeName) customTypesDeclarations.push(customType);
        tsType = customTypeName ?? 'never';
      } else {
        tsType = variable.data.variableType.type;
        if (variable.data.variableType.selectedKind === 'list') {
          tsType = `(${tsType})[]`;
        }
      }
      const jsDoc = variable.data.description
        ? `/** @description ${variable.data.description} */ \n  `
        : '';
      return `  ${jsDoc}${variable.traceKey.value}: ${tsType};`;
    };

    const allLines = globalVariableAtoms.map(getVariableLine);
    return `${customTypesDeclarations.join('\n')}

type ${DynamicType.GlobalState} = {\n${allLines.join('\n')}\n};`;
  }
}
