import { createZodDto } from 'nestjs-zod';
import z from 'zod';

import { ActionSchema } from '../action';
import { DNDSchema, GatewaySchema } from '../bpmn';
import { EventSchema } from '../bpmn/event.schema';

export const WorkflowMetadataSchema = z
  .object({
    name: z.string().optional(),
    bpmn_js_id: z.string().optional(),
    id_environment_variable_name: z.string().optional()
  })
  .strict();

export const BaseWorkflowSchema = z.object({
  id: z.string(),
  published_id: z.string().nullable(),
  workflow_xml: z.string(),
  metadata: WorkflowMetadataSchema
});

// Schema for processes
export const WorkflowSchema = BaseWorkflowSchema.extend({
  Event: z.array(EventSchema),
  Action: z.array(ActionSchema),
  Gateway: z.array(GatewaySchema),
  globalVariables: DNDSchema,
  traceInfo: DNDSchema,
  traceOverview: DNDSchema
});

export const UpdateWorkflowSchema = z.object({
  metadata: WorkflowMetadataSchema.optional(),
  workflow_xml: z.string().optional()
});

export type Workflow = z.infer<typeof WorkflowSchema>;
export type BaseWorkflow = z.infer<typeof BaseWorkflowSchema>;
export type StratumnProcessGeneratedInfo = {
  workflowId: string;
  workflowPublishedId: string | null;
}[];

export class UpdateWorkflowDto extends createZodDto(UpdateWorkflowSchema) {}
