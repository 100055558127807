import { useMemo, useRef, useState } from 'react';

import {
  CellEditingStoppedEvent,
  CellKeyDownEvent,
  ColGroupDef,
  ValueFormatterParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AtomReference } from 'shared/src/atom/atomReference.schema';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { ResolvedVariableType } from 'shared/src/atom/variables';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import nanoID from '@/utils/nanoID';
import { CircularProgress } from '@mui/joy';

import { GridContainer } from './grid.style';

const Grid = () => {
  const { atomStore, modalStore } = useStores();
  const process = useProcess();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const [inputRow, setInputRow] = useState<RowData>({ rowAtomId: nanoID() });
  const gridRef = useRef<any>(null);

  if (!process || process.loading) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  const database = process.databases.find((db) => db.id === databaseId);

  if (!database) return;

  const canUserEdit = () => {
    return process.getPermission().can_edit;
  };

  const isUserOwner = () => {
    return process.getPermission().can_delete;
  };

  const defaultColDef: Partial<ColGroupDef> = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      // floatingFilter: true,
      filter: true,
      resizable: false,
      editable: canUserEdit(),
      wrapHeaderText: true,
      autoHeaderHeight: true,
      valueFormatter: (params: ValueFormatterParams) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : params.value
    }),
    []
  );

  const onCellkeyDown = async (event: CellKeyDownEvent<any, RowData>) => {
    const keyboardEvent = event.event;

    if (!(keyboardEvent instanceof KeyboardEvent)) return;

    if (keyboardEvent.code === 'Delete') {
      if (!isUserOwner()) return;

      const selectedRows = event.api.getSelectedRows() as RowData[]; //TODO: create real typed api
      if (selectedRows.length === 0) return;

      for (const selectedRow of selectedRows) {
        const rowAtomId = selectedRow.rowAtomId;

        const atomToDelete = atomStore.getAtomById<RowData>(rowAtomId, 'Row');

        if (!atomToDelete || atomToDelete instanceof Error) return;

        if (!atomToDelete.isDeletable) {
          modalStore.referenceModal.open(
            'atom',
            atomToDelete.id,
            'referencedBy',
            atomToDelete.type
          );
          return;
        }

        const isAtomDeleted = await atomStore.deleteAtom(rowAtomId);
        if (!isAtomDeleted) return;

        database.removeRowReference(rowAtomId);
      }
    }
  };

  const isEmptyPinnedCell = (params) => {
    return (
      (params.node.rowPinned === 'top' && params.value == null) ||
      (params.node.rowPinned === 'top' && params.value === '')
    );
  };

  const createPinnedCellPlaceholder = ({ colDef }) => {
    return 'New '.concat(colDef.field).concat('...');
  };

  const isPinnedRowDataCompleted = (event: CellEditingStoppedEvent) => {
    if (event.rowPinned !== 'top') return;

    return database.getColumns.every((def) => inputRow[def.field ?? '']);
  };

  const isAReferenceColumn = (event: CellEditingStoppedEvent) => {
    return event.colDef.context?.reference !== undefined;
  };

  const createNewRowAtom = (data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          parentId: database.id,
          elementId: database.id,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: 'Row' as ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  const updateRowAtomsReferences = (
    event: CellEditingStoppedEvent<any, AtomReference[]>
  ) => {
    const concernedRowAtomId = event.data.rowAtomId;
    const newReferences = event.value;
    const databaseReferenced = event.colDef.context?.reference
      .databaseId as string;

    const concernedRowAtom = atomStore.getAtomById<RowData>(
      concernedRowAtomId, //todo: fix this
      'Row'
    );

    if (!concernedRowAtom || concernedRowAtom instanceof Error) {
      newError(
        'AGMSE-8gAqW',
        `Parent row atom ${concernedRowAtomId} not found while updating references, 
          references will not be updated`
      );
      return;
    }

    concernedRowAtom.refreshAllReferences(
      newReferences ? newReferences : [],
      databaseReferenced
    );
  };

  const createNewRow = (event: CellEditingStoppedEvent) => {
    const newRowAtom = createNewRowAtom(inputRow);
    if (!newRowAtom || newRowAtom instanceof Error) return;
    setInputRow({ rowAtomId: nanoID() });
    database.addRowReference({
      dataItemId: newRowAtom.id,
      sourceId: database.id,
      blockType: 'Row'
    });
  };

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    if (isPinnedRowDataCompleted(event)) {
      createNewRow(event);
    }
    if (isAReferenceColumn(event)) {
      updateRowAtomsReferences(event);
    }
  };

  return (
    <GridContainer className="ag-theme-quartz">
      <AgGridReact
        ref={gridRef}
        rowData={database.getRowAtoms().map((atom) => atom.data)}
        onCellKeyDown={onCellkeyDown}
        columnDefs={database.getGridColumnDefinitions()}
        defaultColDef={defaultColDef}
        onCellEditingStopped={onCellEditingStopped}
        rowSelection={{
          mode: 'multiRow',
          isRowSelectable: isUserOwner
        }}
        containerStyle={{
          wrapperBorder: false
        }}
        autoSizeStrategy={{
          type: 'fitGridWidth'
        }}
        // domLayout="autoHeight"
        pinnedTopRowData={[inputRow]}
        groupDisplayType={'multipleColumns'}
        animateRows={true}
        rowGroupPanelShow={'always'}
      />
    </GridContainer>
  );
};

export default observer(Grid);
