import { useEffect, useRef, useState } from 'react';

import { ChevronsUpDown, Rocket, Share2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { StudioUser } from 'shared';
import { toast } from 'sonner';
import { useTheme } from 'styled-components';

import ShareDialog from '@components/share';
import { Avatar, AvatarFallback, AvatarImage } from '@components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@components/ui/dropdown-menu';
import { Skeleton } from '@components/ui/skeleton';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';
import Logo from '@atoms/logo/logo.comp';

import { ParamsList } from '@/routes/routes.types';
import {
  ACCOUNT_UI_URL,
  ENV,
  EnvironmentName,
  TRACE_UI_URL
} from '@/utils/constants';
import { logout } from '@/utils/logout';
import { Button, Dropdown, MenuButton } from '@mui/joy';

import {
  CustomMenuItem,
  EnvironmentSpan,
  NavbarEditorMenu,
  ProcessList
} from './header.style';
import { MiddleNavBar } from './middle.comp';
import { ProcessSelector } from './processSelector';
import { WorkflowSelector } from './workflowSelector';

interface HeaderProps {
  user: StudioUser;
}

const Header = ({ user }: HeaderProps) => {
  const theme = useTheme();
  const { processStore } = useStores();
  const process = useProcess();
  const currentWorkflowId = useParams()[ParamsList.WorkflowId];
  const isEnvironmentDisplayed: boolean =
    ENV !== EnvironmentName.Release &&
    !!ENV &&
    Object.values(EnvironmentName).includes(ENV);

  const inputRef = useRef<HTMLInputElement>(null);
  const [openModalShare, setOpenModalShare] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const hasBeenGenerated =
    (process?.workflows &&
      process?.workflows.some((wf) => wf.published_id !== null)) ||
    process?.isPublished();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSelectorOpen]);

  const generateProcess = () => {
    const prom = processStore.generateProcess(process?.id ?? '');
    toast.promise(prom, {
      loading: 'Generating your process...',
      success: (data) => {
        if ('workflowPublishedId' in data) {
          return `Your process has been successfully generated!`;
        } else {
          return 'Your process generation has been queued!';
        }
      },
      error: (error) => {
        if (error instanceof Error) {
          return 'An error occured while generating your process: '.concat(
            error.message
          );
        }
        return 'An error occured while generating your process: '.concat(
          JSON.stringify(error, Object.getOwnPropertyNames(error))
        );
      }
    });
  };

  return (
    <>
      <header
        className="w-full flex-row justify-between h-[60px] flex items-center bg-white pr-4 pl-1 z-10
        border-b border-solid border-gray-200
      "
      >
        {process?.getPermission().can_edit && (
          <div className="fixed w-full top-[15px] z-[100]">
            <div className="relative w-full flex flex-col items-center ">
              <MiddleNavBar />
            </div>
          </div>
        )}

        <div className="flex z-[100] flex-row items-center w-[40%]">
          <Logo />
          <p className="text-gray-200 pl-0 pr-1">/</p>

          <ProcessSelector />

          {currentWorkflowId && (
            <>
              <p className="text-gray-200 p-2 pr-1">/</p>
              {process ? (
                <WorkflowSelector />
              ) : (
                <Skeleton className="w-[100px] h-[20px]" />
              )}
            </>
          )}

          {isEnvironmentDisplayed && <EnvironmentSpan>{ENV}</EnvironmentSpan>}
        </div>

        <div className="flex  z-[100] felx-col space-x-3">
          {process !== undefined && (
            <>
              <>
                {process?.getPermission().can_edit && (
                  <ActionButton
                    value="Generate"
                    startDecorator={<Rocket size={18} />}
                    size="sm"
                    onClick={generateProcess}
                  />
                )}

                {hasBeenGenerated &&
                  (process?.workflows.length === 1 ? (
                    <Link
                      to={`${TRACE_UI_URL}/workflowoverview/${process?.workflows[0].published_id}`}
                      target="_blank"
                    >
                      <Button variant="outlined">Trace</Button>{' '}
                    </Link>
                  ) : (
                    <Dropdown
                      onOpenChange={() => setIsSelectorOpen(!isSelectorOpen)}
                    >
                      <MenuButton
                        size="sm"
                        endDecorator={
                          <ChevronsUpDown
                            color={theme.color.grey[6]}
                            size={14}
                          />
                        }
                        variant="outlined"
                        sx={{ gap: '10px' }}
                      >
                        Trace
                      </MenuButton>

                      <NavbarEditorMenu>
                        <ProcessList>
                          {process?.workflows.map((wf) => (
                            <CustomMenuItem key={wf.id}>
                              <Link
                                to={`${TRACE_UI_URL}/workflowoverview/${wf.published_id}`}
                                target="_blank"
                              >
                                Workflow {wf.published_id}
                              </Link>
                            </CustomMenuItem>
                          ))}
                        </ProcessList>
                      </NavbarEditorMenu>
                    </Dropdown>
                  ))}
              </>
              <Button
                color="primary"
                variant="plain"
                size="sm"
                onClick={() => {
                  setOpenModalShare(!openModalShare);
                }}
                startDecorator={<Share2 size={18} />}
              />
            </>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="cursor-pointer h-9 w-9">
                <AvatarImage
                  src={
                    user?.avatar != null
                      ? user?.avatar
                      : 'https://www.w3schools.com/howto/img_avatar.png'
                  }
                />
                {user && (
                  <AvatarFallback>{`${user.firstName && user.firstName[0]}${user.lastName && user?.lastName[0]}`}</AvatarFallback>
                )}
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-2 border border-solid border-gray-200">
              <DropdownMenuLabel>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="text-xs leading-none text-muted-foreground">
                    {user.email}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => (location.href = ACCOUNT_UI_URL)}
                >
                  Account
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => logout()}
                >
                  Logout
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      {process && (
        <ShareDialog
          key="modal"
          openModalShare={openModalShare}
          setOpenModalShare={setOpenModalShare}
          user={user}
          processId={process?.id}
        />
      )}
    </>
  );
};

export default observer(Header);
