import React from 'react';

import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { MenuItem } from '@pages/Settings/menu';

import useProcess from '@hooks/useProcess';

import { cn } from '@/lib/utils';
import { ParamsList } from '@/routes/routes.types';

const MENU_ITEMS: Omit<MenuItem, 'icon'>[] = [
  {
    id: 1,
    name: 'Editor',
    link: 'editor'
  },
  {
    id: 2,
    name: 'Database',
    link: 'database'
  },
  {
    id: 3,
    name: 'Settings',
    link: 'settings'
  }
];

export const MiddleNavBar = () => {
  const processId = useParams()[ParamsList.ProcessId] as string;
  const workflowId = useParams()[ParamsList.WorkflowId] as string;
  const process = useProcess();

  const [tabBoundingBox, setTabBoundingBox] = React.useState<Maybe<DOMRect>>();
  const [wrapperBoundingBox, setWrapperBoundingBox] =
    React.useState<Maybe<DOMRect>>();
  const [highlightedTab, setHighlightedTab] = React.useState<boolean | null>(
    null
  );
  const [isHoveredFromNull, setIsHoveredFromNull] = React.useState(true);

  const highlightRef = React.useRef(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const repositionHighlight = (e: any, tab: boolean) => {
    setTabBoundingBox(
      // eslint-disable-next-line
      e.target!.getBoundingClientRect() as unknown as Maybe<DOMRect>
    );
    setWrapperBoundingBox(wrapperRef.current?.getBoundingClientRect());
    setIsHoveredFromNull(!highlightedTab);
    setHighlightedTab(tab);
  };

  const resetHighlight = () => {
    setHighlightedTab(null);
  };

  const highlightStyles: React.CSSProperties = {};

  if (tabBoundingBox && wrapperBoundingBox) {
    highlightStyles.transitionDuration = isHoveredFromNull ? '0ms' : '150ms';
    highlightStyles.width = `${tabBoundingBox.width}px`;
    highlightStyles.opacity = highlightedTab ? 1 : 0;
    highlightStyles.transform = `translate(${
      tabBoundingBox.left - wrapperBoundingBox.left
    }px)`;
  }

  const computedWorkflowId = workflowId
    ? workflowId
    : process?.getWorkflowIds()[0]
      ? process.getWorkflowIds()[0]
      : 'undefinedWfId';

  return (
    <div
      ref={wrapperRef}
      className="relative pl-[23px] flex flex-row gap-x-1 border-solid border-gray-300"
      onMouseLeave={resetHighlight}
    >
      <TabsHighlight
        className="bg-gray-200"
        ref={highlightRef}
        style={highlightStyles}
      />
      {MENU_ITEMS.map(({ name, link }) => (
        <NavLink
          key={link}
          to={
            link == 'database'
              ? `/${processId}/database`
              : `/${processId}/${computedWorkflowId}/${link}`
          }
          onMouseOver={(ev) => repositionHighlight(ev, true)}
          className={({ isActive }) =>
            cn([
              'relative text-[14px] font-normal transition-all',
              isActive
                ? 'text-gray-900 outline outline-1 shadow-md outline-gray-300 bg-white/70 rounded-sm'
                : 'text-gray-400 bg-transparent'
            ])
          }
        >
          <div className="transition-all group-[.active]:bg-gray-300 group-[.active]:text-gray-900  rounded-md px-3 py-1 hover:text-gray-800">
            {name}
          </div>
        </NavLink>
      ))}
    </div>
  );
};

const TabsHighlight = styled.div`
  position: absolute;
  left: 0;
  border-radius: 4px;
  height: 31px;
  transition: 0.15s ease;
  transition-property: width, transform, opacity;
`;
