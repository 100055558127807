import { useEffect, useState } from 'react';

import { ChevronDown, Eye, EyeOff, Forward, Link } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';
import { StudioUser } from 'shared';
import { toast } from 'sonner';
import { useTheme } from 'styled-components';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { BasicModal } from '@atoms/modal';

import { newError } from '@/services/errors/errors';
import { DeleteRoleDraft, UserRoles } from '@/types/process.types';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Dropdown,
  List,
  ListItem,
  ListItemDecorator,
  MenuButton,
  Switch,
  Typography
} from '@mui/joy';

import {
  ButtonShowPassword,
  CustomMenuButton,
  CustomMenuItem,
  FormContainer,
  FormContainerInput,
  FormLabelCustom,
  PasswordContainer,
  RoleMenuContainer,
  StyledTagInputContainer,
  SwitchContainer
} from './share.style';
import {
  MenuRoleChoice,
  PublicAccessChoice,
  generateStrongPassword,
  permissionsToUserRole,
  renderPermissions
} from './utils';

const DEFAULT_ICON = '/src/assets/images/sia.png';

interface ShareProps {
  openModalShare: boolean;
  setOpenModalShare: React.Dispatch<React.SetStateAction<boolean>>;
  user: StudioUser;
  processId: string;
}

const ShareDialog = ({
  openModalShare,
  setOpenModalShare,
  user,
  processId
}: ShareProps) => {
  const process = useProcess();

  const { processStore } = useStores();
  const navigate = useNavigate();
  const theme = useTheme();
  const [emails, setEmails] = useState<string[]>([]);
  const [role, setRole] = useState(MenuRoleChoice[0]);
  const [password, setPassword] = useState(generateStrongPassword(12));
  const [showPassword, setShowPassword] = useState(true);
  const [isShowingProtectedPhrase, setIsShowingProtectedPhrase] =
    useState(false);
  const user_role = process && permissionsToUserRole(process.getPermission());

  const canEdit = process?.getPermission().can_edit;
  const isProtected = process?.getPassword() !== undefined;
  const isPublic = process?.getIsPublic();
  const collaborators = process?.getCollaborators();
  const [publicAccessText, setPublicAccessText] = useState(
    isPublic
      ? 'Anyone with the link can view the process'
      : 'Only people invited can view the process'
  );

  const [switchChecked, setSwitchChecked] = useState(isProtected);
  const [linkCopiedtext, setLinkCopiedText] = useState('Use Public link');
  const [passwordCopiedtext, setPasswordCopiedText] = useState('Copy');

  useEffect(() => {
    setEmails([]);
  }, [openModalShare]);

  useEffect(() => {
    setSwitchChecked(isProtected);
    setPublicAccessText(
      isPublic
        ? 'Anyone with the link can view the process'
        : 'Only people invited can view the process'
    );
  }, [isProtected, isPublic]);

  useEffect(() => {
    setIsShowingProtectedPhrase(isProtected ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Because we dont want this to fire each time isProtected is changed

  if (!process || !user_role) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  const handleSubmit = async (): Promise<void | Error> => {
    // I cant invite me or another email already invited
    const matchedCollaborators = process
      ?.getCollaborators()
      ?.filter((collaborator) =>
        emails.some((email) => email === collaborator.email)
      );
    if (matchedCollaborators && matchedCollaborators.length > 0) {
      return newError(
        'INV-1000',
        'One of the email is already invited in the process',
        true
      );
    }
    const response = await processStore.inviteUser(
      processId,
      emails,
      role.value
    );

    if (response) {
      toast.success('Invitation sent');
    }
    setEmails([]);
  };

  const handleCopy = async (text: 'link' | 'password') => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          text === 'link' ? window.location.href : password
        );
      } catch (error) {
        newError('INV-002', `Couldn't copy the ${text}`);
      }
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = `${text} copied in clipboard`;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }

    if (text === 'link') {
      setLinkCopiedText('Link copied ! ✅ ');
      setTimeout(() => setLinkCopiedText('Use public link'), 2000);
      toast.info('Link copied');
    } else {
      setPasswordCopiedText('Copied ! ✅ ');
      setTimeout(() => setPasswordCopiedText('Copy'), 2000);
      toast.info('Password copied');
    }
  };
  const togglePassword = async () => {
    setSwitchChecked(!switchChecked);
    setShowPassword(false);
    setIsShowingProtectedPhrase(false);
    const newGeneratedPassword = generateStrongPassword(12);
    const password = switchChecked ? '' : newGeneratedPassword;

    const response = await processStore.updateProcess(processId, { password });
    if (response.statusCode) {
      setPassword(newGeneratedPassword);
      toast.success(
        !switchChecked
          ? 'Password generated and link protected'
          : 'Password removed successfully'
      );
    }
  };

  const handlePublicAccess = async (isPublic: boolean) => {
    const response = await processStore.updateProcess(processId, {
      is_public: isPublic
    });

    setPublicAccessText(
      isPublic
        ? 'Anyone with the link can view the process'
        : 'Only people invited can view the process'
    );

    if (response.statusCode)
      toast.success('Public access successfully updated');
  };

  const changeCollaboratorRole = async (
    user: string,
    newValue: UserRoles | DeleteRoleDraft
  ) => {
    if (
      newValue === DeleteRoleDraft.Leave ||
      newValue === DeleteRoleDraft.Remove
    ) {
      await processStore.deleteRole(processId, user);
      if (newValue === DeleteRoleDraft.Leave) navigate('/');
      toast.success('Role successfully deleted');
    } else {
      await processStore.updateRole(processId, newValue, user);
      toast.success('Role successfully updated');
    }
  };
  const userPermissions = renderPermissions(user_role);

  return (
    <BasicModal
      open={openModalShare}
      setOpen={setOpenModalShare}
      startIcon={<Forward />}
      title={`Sharing the draft`}
    >
      <Divider />
      <FormContainer>
        <FormLabelCustom>Public sharing options</FormLabelCustom>
        <FormContainerInput>
          <Typography>
            <Button
              variant="outlined"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handleCopy('link')}
            >
              <Link size={16} style={{ marginRight: 8 }} />
              {linkCopiedtext}
            </Button>
          </Typography>
          <Box display="flex" gap={4} alignItems={'center'}>
            {canEdit ? (
              <Dropdown>
                <MenuButton
                  size="sm"
                  variant="plain"
                  endDecorator={
                    <ChevronDown color={theme.color.grey[3]} size={12} />
                  }
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <Typography fontSize={14} fontWeight={'normal'}>
                    {publicAccessText}
                  </Typography>
                </MenuButton>
                <RoleMenuContainer>
                  {PublicAccessChoice.map((accessChoice) => {
                    return (
                      <CustomMenuItem
                        key={accessChoice.label}
                        onClick={() => {
                          void handlePublicAccess(accessChoice.value);
                        }}
                      >
                        <Typography level="body-sm">
                          {accessChoice.label}
                        </Typography>
                      </CustomMenuItem>
                    );
                  })}
                </RoleMenuContainer>
              </Dropdown>
            ) : (
              <Typography level="body-sm">{publicAccessText}</Typography>
            )}
          </Box>
        </FormContainerInput>
        {canEdit && (
          <FormContainerInput>
            <SwitchContainer>
              <Switch
                size={'md'}
                sx={{
                  '--Switch-trackWidth': '38px'
                }}
                checked={switchChecked}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={togglePassword}
              />
              <Typography>
                {switchChecked ? 'Password protected' : 'Protect via password'}
              </Typography>
            </SwitchContainer>

            {switchChecked &&
              (isShowingProtectedPhrase ? (
                <Typography level="body-sm">
                  Link is protected by a password
                </Typography>
              ) : (
                <SwitchContainer>
                  <PasswordContainer>
                    <Typography color="neutral">
                      {showPassword ? password : '●'.repeat(password.length)}
                    </Typography>
                    <ButtonShowPassword
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <EyeOff size={15} /> : <Eye size={15} />}
                    </ButtonShowPassword>
                  </PasswordContainer>

                  <Button
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() => handleCopy('password')}
                    sx={{ width: 120 }}
                  >
                    {passwordCopiedtext}
                  </Button>
                </SwitchContainer>
              ))}
          </FormContainerInput>
        )}
      </FormContainer>
      <Divider />
      <FormContainer>
        <FormLabelCustom>Private sharing options</FormLabelCustom>
        {canEdit && (
          <FormContainerInput>
            <StyledTagInputContainer>
              <TagsInput
                value={emails}
                onChange={setEmails}
                name="emails"
                placeHolder={'Enter email address'}
                separators={['Enter', 'Tab']}
                beforeAddValidate={(tag) => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  return emailRegex.test(tag);
                }}
              />
              <Dropdown>
                <MenuButton
                  size="sm"
                  variant="plain"
                  endDecorator={
                    <ChevronDown color={theme.color.grey[6]} size={14} />
                  }
                  sx={{
                    width: '120px',
                    fontSize: '12px',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  can {role.label}
                </MenuButton>
                <RoleMenuContainer>
                  {MenuRoleChoice.filter(
                    (choice) => choice.value !== role.value
                  ).map((roleChoice) => {
                    return (
                      <CustomMenuItem
                        key={roleChoice.value}
                        onClick={() => setRole({ ...role, ...roleChoice })}
                      >
                        <Typography fontSize={12}>
                          can {roleChoice.label}
                        </Typography>
                      </CustomMenuItem>
                    );
                  })}
                </RoleMenuContainer>
              </Dropdown>
            </StyledTagInputContainer>
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit}
              disabled={emails.length === 0}
            >
              Invite
            </Button>
          </FormContainerInput>
        )}
        <List
          sx={{
            '--ListItem-paddingX': '0rem'
          }}
        >
          {collaborators?.map((collaborator, index) => {
            const isMe = collaborator.email === user.email;

            // Add Remove or Delete actions in the dropdown:
            const parseUserPermissions = userPermissions.concat({
              label: isMe ? 'leave' : 'remove',
              value: isMe ? DeleteRoleDraft.Leave : DeleteRoleDraft.Remove
            });
            return (
              <ListItem
                key={index}
                endAction={
                  collaborator.permissions.can_delete || (!canEdit && !isMe) ? (
                    <Typography fontSize={12}>
                      {permissionsToUserRole(collaborator.permissions)}
                    </Typography>
                  ) : (
                    <Dropdown>
                      <CustomMenuButton
                        size="sm"
                        variant="plain"
                        sx={{
                          fontSize: 12,
                          padding: 0,
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                        endDecorator={
                          <ChevronDown
                            color={theme.color.grey[3]}
                            size={14}
                            style={{ padding: 0 }}
                          />
                        }
                      >
                        {permissionsToUserRole(collaborator.permissions)}
                      </CustomMenuButton>
                      <RoleMenuContainer>
                        {parseUserPermissions
                          .filter(
                            // show in dropdown the remaining permissions
                            (permission: {
                              label: string;
                              value: UserRoles | DeleteRoleDraft;
                            }) =>
                              permission.value !==
                              permissionsToUserRole(collaborator.permissions)
                          )
                          .filter((permission) => {
                            // if isMe, show in dropdown only "leave"
                            if (isMe) {
                              return permission.value === DeleteRoleDraft.Leave;
                            } else return permission;
                          })
                          .map((permission, index) => (
                            <CustomMenuItem
                              key={index}
                              disabled={!collaborator.id}
                              onClick={() => {
                                if (collaborator.id)
                                  void changeCollaboratorRole(
                                    collaborator.id,
                                    permission.value
                                  );
                              }}
                            >
                              <Typography fontSize={12}>
                                {permission.label}
                              </Typography>
                            </CustomMenuItem>
                          ))}
                      </RoleMenuContainer>
                    </Dropdown>
                  )
                }
              >
                <ListItemDecorator>
                  <Avatar
                    sx={{ height: '25px', width: '25px' }}
                    src={
                      collaborator.avatar && collaborator.avatar !== ''
                        ? collaborator.avatar
                        : DEFAULT_ICON
                    }
                  />
                </ListItemDecorator>
                {collaborator.name ?? collaborator.email}
                {isMe && ' (you)'}
              </ListItem>
            );
          })}
        </List>
      </FormContainer>
    </BasicModal>
  );
};

export default observer(ShareDialog);
