import { computed, makeObservable, observable, reaction, toJS } from 'mobx';
import {
  AtomType,
  CheckBoxData,
  DiscriminatedAtomData,
  DndKitValuesSchema,
  DropDownData,
  FB_TYPES,
  FbBlocks,
  MetaInfo,
  StaticType,
  TraceKey,
  VariableInfo
} from 'shared';
import { AtomReference } from 'shared/src/atom/atomReference.schema';
import { toast } from 'sonner';
import { z } from 'zod';

import { ActionModel } from '@models/action/action.model';
import { ModelError } from '@models/base/base.model';
import { BaseModelWithTraceKey } from '@models/base/baseWithKey.model';
import { DNDModel } from '@models/dnd.model';

import AtomStore from '@stores/atom.store';

import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';
import { arrayToUnionType } from '@/utils/typescript/arrayToUnionType';

export class AtomModel<TData = unknown> extends BaseModelWithTraceKey {
  constructor(
    store: AtomStore,
    id: string,
    traceKeyDTO: TraceKey,
    public type: AtomType,
    public data: TData,

    /** Infos related to the data such as dnd and source info */
    public metaInfo: MetaInfo,
    public referencedBy: AtomModel['id'][],
    public references: AtomModel['id'][],
    public processId: string,
    public variableInfo?: VariableInfo
  ) {
    super(store, id, traceKeyDTO);

    makeObservable(this, {
      data: observable,
      referencedBy: observable,
      references: observable,
      toJSON: computed
    });

    reaction(
      () => ({
        data: toJS(this.data),
        referencedBy: toJS(this.referencedBy),
        references: toJS(this.references),
        traceKey: toJS(this.traceKey.toJSON)
      }),
      () => {
        if (['local', 'staging'].includes(ENV)) {
          toast.info(`Data updated ${this.type} ${this.id.substring(0, 6)}`);
        }

        this.store.update(this.id).catch((error: Error) => {
          newError('ATOM-495b0', error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => toJS(this.data),
      () => this.store.rootStore.codeStore.lastCodeModel?.recomputePreCode(),
      { delay: 200 }
    );

    reaction(
      () => {
        if (this.isAtomWithTitle()) return this.data.title;
        return null;
      },
      () => {
        if (!this.traceKey) return;
        // @ts-expect-error at this point, we know that this is an Atom with title
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.traceKey.followName(this.data.title);
      }
    );
  }

  /** Retrives the possible values from a data item.
   * In the case of a dropdown or a database, the values are stored in the data item itself inside `values`.
   * In the case of a checkbox, the values are stored in the `normalSource` inside `values`.
   */
  get possibleValues(): string[] {
    if (!this.data || typeof this.data !== 'object') return [];
    let values: unknown = [];

    if ('values' in this.data) {
      // ? case dropdown || database
      values = this.data.values;
    } else if (
      // ? case checkbox with normal source
      'dataSourceData' in this.data &&
      this.data.dataSourceData &&
      typeof this.data.dataSourceData === 'object' &&
      'normalSource' in this.data.dataSourceData &&
      this.data.dataSourceData.normalSource &&
      typeof this.data.dataSourceData.normalSource === 'object' &&
      'values' in this.data.dataSourceData.normalSource
    ) {
      values = this.data.dataSourceData.normalSource.values;
    }

    const parsedValues = parseWithZod(
      DndKitValuesSchema,
      values,
      'ATOM-7cc01',
      {
        withSentry: false
      }
    );
    return parsedValues ? parsedValues.map((value) => value.name) : [];
  }

  /* ---------------------------- References utils ---------------------------- */
  addReferenceToAtom(atomId: AtomModel['id']): boolean {
    if (this.references.includes(atomId)) {
      newError(
        'ATOM-5f5b2',
        `Atom to reference is already in the references of atom "${this.id}".`
      );
      return false;
    }
    this.references.push(atomId);

    const referencedAtom = this.store.get(atomId) as Maybe<AtomModel>;
    if (!referencedAtom) {
      newError(
        'ATOM-ei4JR',
        `Atom to reference not found in store: atomId = "${atomId}".`
      );
      this.references = this.references.filter((id) => id !== atomId);
      return false;
    }

    if (referencedAtom.referencedBy.includes(this.id)) {
      newError(
        'ATOM-5f5b1',
        `Atom "${this.id}" is already in the references of atom "${atomId}".`
      );
      return true;
    }

    referencedAtom.referencedBy.push(this.id);
    return true;
  }

  refreshAllReferences(
    newReferences: AtomReference[],
    databaseRefId: string
  ): boolean {
    const newAtomsRefs: AtomModel[] = [];
    for (const reference of newReferences) {
      const atom = this.store.rootStore.atomStore.getAtomById(
        reference.dataItemId,
        'Row'
      );

      if (!atom || atom instanceof Error) {
        newError(
          'ATOM-MURvi',
          `Atom referenced not found in store while refreshing references: atomId = "${reference.dataItemId}".`
        );
        continue;
      }

      newAtomsRefs.push(atom);
    }

    const referencesToRemove = this.references.filter(
      (id) => !newAtomsRefs.map((atom) => atom.id).includes(id)
    );

    const filteredReferencesToRemove = referencesToRemove.filter((id) => {
      const refAtom = this.store.rootStore.atomStore.getAtomById(id, 'Row');

      if (!refAtom || refAtom instanceof Error) {
        newError(
          'ATOM-MURvi',
          `Atom referenced not found in store while refreshing references: atomId = "${id}".`
        );
        return false;
      }

      return refAtom.metaInfo.source.parentId === databaseRefId;
    });

    const referencesToAdd = newAtomsRefs
      .map((atom) => atom.id)
      .filter((id) => !this.references.includes(id));

    filteredReferencesToRemove.forEach((id) => this.removeReferenceToAtom(id));
    referencesToAdd.forEach((id) => this.addReferenceToAtom(id));

    return true;
  }

  removeReferenceToAtom(atomId: AtomModel['id']): boolean {
    if (!this.references.includes(atomId)) {
      newError(
        'ATOM-5f5b3',
        `Atom to remove not found in the references of atom "${this.id}".`
      );
    }
    this.references = this.references.filter((id) => id !== atomId);

    const referencedAtom = this.store.get(atomId) as Maybe<AtomModel>;
    if (!referencedAtom) {
      newError(
        'ATOM-3b0b9',
        `Atom referenced not found in store: atomId = "${atomId}".`
      );
      return false;
    }

    if (!referencedAtom.referencedBy.includes(this.id)) {
      newError(
        'ATOM-5f5b4',
        `Atom "${this.id}" is not in the referencedBy array of atom "${atomId}".`
      );
      return true;
    }

    referencedAtom.referencedBy = referencedAtom.referencedBy.filter(
      (id) => id !== this.id
    );
    return true;
  }

  get referencedAtoms(): AtomModel[] {
    return this.references
      .map((id) => this.store.get(id))
      .filter((atom): atom is AtomModel => !!atom);
  }

  get isDeletable(): boolean {
    return this.referencedBy.length === 0;
  }

  get dndModel(): Maybe<DNDModel> {
    const action = this.store.rootStore.actionStore.get(
      this.metaInfo.source.elementId
    );
    return action?.formDnd;
  }

  /* -------------------------------------------------------------------------- */
  /*                                 Custom code                                */
  /* -------------------------------------------------------------------------- */

  public getTypeScriptType(blockType: AtomType): string {
    if (!isFormBlock(blockType)) return 'unknown';
    switch (blockType) {
      case 'TextField':
      case 'Rich Text':
        return 'string';
      case 'Drop Down':
      case 'Check Box':
        return this.getMultiSelectType(blockType);
      case 'Number':
        return 'number';
      case 'Date':
        return '`${number}-${number}-${number}`';
      case 'File Upload':
        return `${StaticType.FormFile}[]`; // type provided by the starter file
      case 'Comment':
        return `${StaticType.ActionComment}`;
    }
  }

  private getMultiSelectType(blockType: 'Check Box' | 'Drop Down'): string {
    const atomModel = this as AtomModel<DropDownData | CheckBoxData>;
    let fieldType: string;
    switch (atomModel.data.dataSource) {
      case 'normalSource': {
        if (!atomModel.data.dataSourceData.normalSource?.values) {
          return 'never';
        }
        const values = atomModel.data.dataSourceData.normalSource.values;
        fieldType = arrayToUnionType(values.map((value) => value.name));
        break;
      }
      case 'database': {
        // TODO: rework
        fieldType = 'TODO FOR DATABASES';
        break;
      }
      case 'stateVariable':
        fieldType = 'unknown';
        break;
      default:
        fieldType = 'never';
    }
    if (
      atomModel.isCheckBox(blockType) &&
      atomModel.data.checkboxType === 'checkbox'
    ) {
      fieldType = `(${fieldType})[]`;
    }
    return fieldType;
  }

  private isCheckBox(
    this: AtomModel,
    blockType: AtomType
  ): this is AtomModel<CheckBoxData> {
    return blockType === 'Check Box';
  }

  get source(): Maybe<ActionModel> {
    const sourceInfo = this.metaInfo.source;
    if (sourceInfo.parentKind === 'ui') {
      const action = this.store.rootStore.actionStore.get(sourceInfo.elementId);
      return action;
    }
    return;
  }

  private isAtomWithTitle(): this is AtomModel<{ title: string }> {
    return (
      !!this.data &&
      typeof this.data === 'object' &&
      'title' in this.data &&
      typeof this.data.title === 'string'
    );
  }

  get atomData(): DiscriminatedAtomData<this['type']> {
    const atomData: DiscriminatedAtomData<typeof this.type> = {
      type: this.type,
      data: this.data as DiscriminatedAtomData<typeof this.type>['data']
    };
    return atomData;
  }

  get toJSON() {
    const AtomUpdateDTO = {
      type: this.type,
      data: this.data,
      meta_info: this.metaInfo,
      variable_info: this.variableInfo,
      referenced_by: this.referencedBy,
      references: this.references,
      traceKey: this.traceKey.toJSON
    };
    return toJS(AtomUpdateDTO);
  }

  get errors(): ModelError[] {
    return [];
  }
}

const isFormBlock = (blockType: AtomType): blockType is FbBlocks => {
  return FB_TYPES.includes(blockType as FbBlocks);
};

export const AtomWithTitleSchema = z.object({
  title: z.string()
});
