import { makeObservable, observable, reaction, toJS } from 'mobx';
import { Action, ActionType, CreateActionDTO } from 'shared';

import { TraceTable } from '@components/trace/table';

import { ModelError } from '@models/base/base.model';
import { BaseModelWithTraceKey } from '@models/base/baseWithKey.model';
import { CodeModel } from '@models/code/code.model';
import { DNDModel } from '@models/dnd.model';
import { WorkflowModel } from '@models/workflow.model';

import BaseStore from '@stores/base/base.store';

import { newError } from '@/services/errors/errors';
import { DndLibrary } from '@library';

export class ActionModel extends BaseModelWithTraceKey {
  public static DEFAULT_ACTION_NAME: string = 'New action';
  public actionType: Action['type'];
  public workflowId: WorkflowModel['id'];
  public uiDndId: DNDModel['id'];
  public effectsDndId: DNDModel['id'];
  public _metadata: Action['metadata'];
  public codeModelId: CodeModel['id'];

  constructor(store: BaseStore<ActionModel>, action: Action) {
    super(store, action.id, action.traceKey);
    this.actionType = action.type;
    this._metadata = action.metadata;

    this.workflowId = action.workflowId;
    this.uiDndId = action.ui.id;
    this.effectsDndId = action.effects.id;
    this.codeModelId = action.customCode.id;

    makeObservable(this, {
      _metadata: observable
    });

    reaction(
      () => toJS(this._metadata),
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('ACTIO-f6f66', error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => this.traceKey.toJSON,
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('ACTIO-7152d', error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => this.name,
      () => {
        this.traceKey.followName(this.name);
        const bpmn = this.store.rootStore.workflowStore?.currentWorkflow?.bpmn;
        if (!bpmn?.modeler) return;
        bpmn.changeElementName(this.name || '');
      },
      {
        delay: 50
      }
    );
  }

  public get name(): string {
    return this._metadata.name;
  }

  public get formDnd(): Maybe<DNDModel<DndLibrary.FormBuilder>> {
    return this.store.rootStore.dndStore.get(
      this.uiDndId
    ) as DNDModel<DndLibrary.FormBuilder>;
  }

  /** @deprecated */
  public get effectsDnd(): Maybe<DNDModel<DndLibrary.EffectsBuilder>> {
    return this.store.rootStore.dndStore.get(
      this.effectsDndId
    ) as DNDModel<DndLibrary.EffectsBuilder>;
  }

  public get workflow(): Maybe<WorkflowModel> {
    return this.store.rootStore.workflowStore.get(this.workflowId);
  }

  public get codeModel(): Maybe<CodeModel> {
    return this.store.rootStore.codeStore.get(this.codeModelId);
  }

  public static getCreateDTOByActionType(
    actionType: ActionType,
    workflowId: WorkflowModel['id'],
    bpmnActionId: string
  ): CreateActionDTO {
    const baseCreateDto = {
      bpmn_id: bpmnActionId,
      workflow_id: workflowId
    };
    switch (actionType) {
      case 'FORM':
        return {
          type: 'FORM',
          metadata: {
            name: 'New Form action'
          },
          ...baseCreateDto
        };
      case 'TABLE_IMPORTER':
        return {
          type: 'TABLE_IMPORTER',
          metadata: {
            name: 'New Table Importer',
            settings: TraceTable.InitialData.importerSettingsData
          },
          ...baseCreateDto
        };
      case 'TABLE_EDITOR':
        return {
          type: 'TABLE_EDITOR',
          metadata: {
            name: 'New Table Editor',
            settings: TraceTable.InitialData.editorSettingsData
          },
          ...baseCreateDto
        };
      case 'E_SIGNATURE':
        return {
          type: 'E_SIGNATURE',
          metadata: {
            name: 'New E-Signature action'
          },
          ...baseCreateDto
        };
      case 'BOT':
        return {
          type: 'BOT',
          metadata: {
            name: 'New Bot action'
          },
          ...baseCreateDto
        };
    }
  }

  public async delete(): Promise<boolean> {
    const dndStore = this.store.rootStore.dndStore;

    const uiDnd = dndStore.get(this.uiDndId);
    const effectsDnd = dndStore.get(this.effectsDndId);

    if (!uiDnd || !effectsDnd) {
      newError(
        'ACTIO-f7189',
        `Error while deleting action "${this.id}" one of the DND is missing: formDnd: "${uiDnd?.id}", effectsDnd: "${effectsDnd?.id}"`,
        true,
        {
          description: 'An error occured while deleting the action'
        }
      );
      return false;
    }

    /* ------------------------ Delete the action itself ------------------------ */

    const isActionDeleted = await this.store.delete(this.id);
    if (!isActionDeleted) {
      newError(
        'ACTIO-befae',
        `Action store could not delete the action ${this.id}`,
        false
      );
      return false;
    }

    /* ------------------- Delete action's DNDs in store only ------------------- */

    await dndStore.delete(this.effectsDndId, false);
    await dndStore.delete(this.uiDndId, false);

    return true;
  }

  get toJSON() {
    return {
      metadata: this._metadata,
      traceKey: this.traceKey.toJSON
    };
  }

  /** Checks if the action is the source of non deletable atoms. */
  get isDeletable(): boolean {
    return this.store.rootStore.atomStore.allReferencableAtoms
      .filter((atom) => atom.metaInfo.source.elementId === this.id)
      .map((a) => a.isDeletable)
      .every((a) => a);
  }

  get errors(): ModelError[] {
    return [];
  }
}
