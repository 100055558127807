import { ChevronsUpDown } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@components/ui/popover';
import { Separator } from '@components/ui/separator';

import useProcess from '@hooks/useProcess';
import { useWorkflow } from '@hooks/useWorkflow';

import { WorkflowModel } from '@models/workflow.model';

import { ParamsList } from '@/routes/routes.types';
import theme from '@/styles/theme';

export const WorkflowSelector = observer(() => {
  const navigate = useNavigate();

  const workflowId = useParams()[ParamsList.WorkflowId] as string; 
  const process = useProcess();
  const { workflow } = useWorkflow();


  const changeProcess = (workflowId: WorkflowModel['id']) => {
    navigate(`/${process?.id}/${workflowId}/editor`);
  };
  const workflows = process?.workflows ?? [];
  if (!workflow || !process) return null;
  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex flex-row w-full hover:bg-gray-100 m-1 rounded-sm pl-2 py-1 gap-x-2 items-center select-none cursor-pointer">
          <p className="text-sm text-gray-700 font-semibold">{workflow.name}</p>
          <ChevronsUpDown color={theme.color.grey[6]} size={14} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 z-50">
        <Command loop>
          <CommandInput placeholder="Workflow name" />
          <Separator />
          <CommandList>
            <CommandEmpty>No workflows found.</CommandEmpty>
            <CommandGroup heading="Workflows">
              {workflows.map((w) => (
                <CommandItem
                  defaultChecked={workflowId == w.id}
                  onSelect={() => changeProcess(w.id)}
                >
                  {w.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
});
