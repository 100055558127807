import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';

import useAction from '@hooks/useAction';
import useProcess from '@hooks/useProcess';
import { useWorkflow } from '@hooks/useWorkflow';

import { ParamsList } from '@/routes/routes.types';
import PanelHeader from '@panel/PanelHeader';
import { ACTION_TAB_LINKS } from '@panel/components/action/form/form.panel.config';
import { PanelBody } from '@panel/panel.style';

export const FormPanel = observer(() => {
  const actionId = useParams<string>()[ParamsList.ActionId] as string;
  const process = useProcess();
  const { workflow } = useWorkflow();
  const action = useAction();

  useEffect(() => {
    const bpmn = workflow?.bpmn;
    if (!bpmn) return;
    bpmn.setSelectedElementByElementId(actionId);
    return () => bpmn.resetSelectedElement();
  }, [actionId, workflow?.bpmn]);

  if (!process || !workflow) {
    return <></>;
  }

  return (
    <>
      <PanelHeader
        workflowName={workflow.name ?? 'Error WF'}
        infos={[action?.name ?? 'Error action']}
        actionType="form action"
        tabLinks={ACTION_TAB_LINKS}
      />

      <PanelBody>
        <Outlet />
      </PanelBody>
    </>
  );
});
