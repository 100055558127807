import { EventBusEventCallback } from 'bpmn-js/lib/BaseViewer';
import Modeler from 'bpmn-js/lib/Modeler';
import { CommandStack, EventBus } from 'bpmn-js/lib/features/modeling/Modeling';

import { ProcessModel } from '@models/process.model';
import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { ElementType, ModdleElement } from '@/types/bpmn.types';
import {
  BpmnEvent,
  CreateShapeEvent,
  CreateTransitionEvent,
  DeleteShapeEvent,
  StudioEvent
} from '@/types/event.types';

import { onShapeCreated, onShapeDelete } from './shape';
import { DeleteButtonPressed, deleteButtonPressed } from './shape/action';
import { onTransitionCreated, onTransitionDeleted } from './transition';
import { undoHandler } from './undo/undo';
import { onElementsChanged } from './xml/update';

export abstract class BpmnEventHandlerCreator {
  public abstract createHandler(
    modeler: Modeler,
    rootStore: RootStore
  ): BpmnEventHandler;
}

export class StratumnBpmnEventHandlerCreator extends BpmnEventHandlerCreator {
  public createHandler(
    modeler: Modeler,
    rootStore: RootStore
  ): BpmnEventHandler {
    return new StratumnBpmnEventHandler(modeler, rootStore);
  }
}

export class DraftBpmnEventHandlerCreator extends BpmnEventHandlerCreator {
  public createHandler(modeler: Modeler): BpmnEventHandler {
    return new DraftBpmnEventHandler(modeler);
  }
}

interface BpmnEventHandler {
  listenToEvents(
    processModel: ProcessModel,
    workflowModel: WorkflowModel
  ): void;
}

class StratumnBpmnEventHandler implements BpmnEventHandler {
  private eventBus: EventBus;
  private modeler: Modeler;
  private rootStore: RootStore;
  private commandStack: CommandStack;

  constructor(receivedModeler: Modeler, rootStore: RootStore) {
    this.modeler = receivedModeler;
    this.eventBus = this.modeler.get<EventBus>('eventBus');
    this.commandStack = this.modeler.get<CommandStack>('commandStack');
    this.rootStore = rootStore;
    this.commandStack.clear();
  }

  public listenToEvents(
    processModel: ProcessModel,
    workflowModel: WorkflowModel
  ): void {
    /* ----------------------------- Shape listeners ---------------------------- */
    this.registerEvent(
      'studio.shape.create',
      async (event: CreateShapeEvent) => {
        const res = await onShapeCreated(event, workflowModel, this.rootStore);

        if (!res) {
          this.commandStack.undo();
        }
      }
    );
    this.registerEvent(
      'studio.shape.delete',
      async (event: DeleteShapeEvent) => {
        const res = await onShapeDelete(event, workflowModel, this.rootStore);

        if (!res) {
          this.commandStack.undo();
        }
      }
    );

    /* -------------------------- Transition listeners -------------------------- */
    this.registerEvent(
      'studio.connection.create',
      async (event: CreateTransitionEvent) => {
        const res = await onTransitionCreated(
          event,
          this.rootStore,
          processModel.id
        );

        if (!res) {
          this.commandStack.undo();
        }
      }
    );

    this.registerEvent(
      'studio.connection.delete',
      async (event: CreateTransitionEvent) => {
        const res = await onTransitionDeleted(event, this.rootStore);

        if (!res) {
          this.commandStack.undo();
        }
      }
    );

    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent('elements.changed', () => {
      return onElementsChanged(this.modeler, processModel, workflowModel);
    });

    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent<ModdleElement>('element.changed', (event) => {
      // @ts-expect-error this is fine 🔥
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event?.element?.di?.bpmnElement?.$type === ElementType.Lane) {
        this.rootStore.codeStore.lastCodeModel?.recomputePreCode();
      }
    });

    this.registerEvent<ModdleElement>('element.changed', (event) => {
      /* eslint-disable */
      // @ts-ignore
      if (event?.element?.di?.bpmnElement?.$type === ElementType.Participant) {
        // @ts-ignore
        const name = event?.element?.di?.bpmnElement?.name;
        if (!name || typeof name != 'string') {
          newError('BPMN-451c2', 'workflow name cannot be found', true, {
            description: 'Workflow name cannot be found'
          });
        } else {
          workflowModel.name = name;
        }
      }
      /* eslint-enable */
    });

    /* ----------------------------- Studio listener ---------------------------- */
    this.registerEvent('studio.undo', undoHandler);
    this.registerEvent('studio.action.delete', (event: DeleteButtonPressed) =>
      deleteButtonPressed(event, this.rootStore)
    );
  }

  private registerEvent<T>(
    eventName: BpmnEvent | StudioEvent,
    callback: EventBusEventCallback<T>
  ) {
    this.eventBus.on(eventName, callback);
  }
}

class DraftBpmnEventHandler implements BpmnEventHandler {
  private eventBus: EventBus;
  private modeler: Modeler;

  constructor(receivedModeler: Modeler) {
    this.modeler = receivedModeler;
    this.eventBus = this.modeler.get<EventBus>('eventBus');
  }

  public listenToEvents(
    processModel: ProcessModel,
    workflowModel: WorkflowModel
  ): void {
    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent('elements.changed', () =>
      onElementsChanged(this.modeler, processModel, workflowModel)
    );
  }

  private registerEvent<T>(
    eventName: BpmnEvent | StudioEvent,
    callback: EventBusEventCallback<T>
  ) {
    this.eventBus.on(eventName, callback);
  }
}
