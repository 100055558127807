import { computed, makeObservable, observable, reaction } from 'mobx';
import { GenerationStatus, Pod, PodStatus, StudioUser } from 'shared';

import GenerationStore from '@stores/generation.store';

import { newError } from '@/services/errors/errors';

import Model, { ModelError } from './base/base.model';

const UNITS_TO_SECONDS = {
  SECOND: 1,
  MINUTE: 60,
  HOUR: 3600,
  DAY: 86400,
  MONTH: 2592000, // 30 days
  YEAR: 31536000 // 365 days
};
const POD_TIMEOUT_THRESHOLD_IN_SECONDS = 600; // 10 minutes

export class GenerationModel extends Model {
  private createdBy: StudioUser;
  private createdAt: string;
  private jobId: string;
  private processId: string;
  private source: string;
  private pods: Pod[];
  private currentlyDeployed: boolean = false;
  private timedOutAt: string | null;

  constructor(
    store: GenerationStore,
    id: string,
    createdBy: StudioUser,
    createdAt: string,
    jobId: string,
    processId: string,
    source: string,
    pods: Pod[],
    currentlyDeployed: boolean,
    timedOutAt: string | null
  ) {
    super(store, id, false);
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.processId = processId;
    this.source = source;
    this.pods = pods;
    this.jobId = jobId;
    this.currentlyDeployed = currentlyDeployed;
    this.timedOutAt = timedOutAt;

    makeObservable<
      GenerationModel,
      | 'createdBy'
      | 'createdAt'
      | 'jobId'
      | 'processId'
      | 'source'
      | 'pods'
      | 'currentlyDeployed'
      | 'timedOutAt'
    >(this, {
      createdBy: observable,
      createdAt: observable,
      jobId: observable,
      processId: observable,
      source: observable,
      pods: observable,
      currentlyDeployed: observable,
      timedOutAt: observable,
      status: computed
    });

    reaction(
      () => this.status,
      () => {
        if (this.status != GenerationStatus.TIMEOUT) return;

        const timedoutAtInMs =
          new Date(this.createdAt).getTime() +
          POD_TIMEOUT_THRESHOLD_IN_SECONDS * 1000;

        this.timedOutAt = new Date(timedoutAtInMs).toISOString();

        this.store.update(this.id).catch((error) => {
          newError(
            'GNMD-s7AKO',
            {
              id: this.id,
              error: JSON.stringify(error),
              message: `Failed to update generation ${this.id}`
            },
            false
          );
        });
      }
    );

    reaction(
      () => this.currentlyDeployed,
      async () => {
        if (this.currentlyDeployed) {
          this.store.rootStore.generationStore.updatePreviouslyDeployedGenerationsInCache(
            this.id
          );
        }

        this.store.update(this.id).catch((error) => {
          newError(
            'GNMD-BuUrh',
            {
              id: this.id,
              error: JSON.stringify(error),
              message: `Failed to update generation ${this.id}`
            },
            false
          );
        });
      }
    );
  }

  /* ------------------------ Class properties getters ------------------------ */
  getCreatedBy(): StudioUser {
    return this.createdBy;
  }

  getCreatedAt(): string {
    return this.createdAt;
  }

  getProcessId(): string {
    return this.processId;
  }

  getSource(): string {
    return this.source;
  }

  getPods(): Pod[] | null {
    return this.pods;
  }

  getCurrentlyDeployed(): boolean {
    return this.currentlyDeployed;
  }

  getTimedOutAt(): string | null {
    return this.timedOutAt;
  }

  /* ------------------------ Class properties setters ------------------------ */
  setPods(pods: Pod[]): void {
    this.pods = pods;
  }

  setCurrentlyDeployed(currentlyDeployed: boolean): void {
    this.currentlyDeployed = currentlyDeployed;
  }

  /* ---------------------------- Computed getters ---------------------------- */
  get targetedPod(): Pod | undefined {
    if (this.pods === null || this.pods.length === 0) {
      return;
    }

    const firstSuccessfullPod = this.pods.find(
      (pod) => pod.status === 'SUCCESS'
    );

    if (firstSuccessfullPod !== undefined) {
      return firstSuccessfullPod;
    }

    const tempPods = Array.from(this.pods);

    tempPods.sort((a, b) => {
      const aDate = new Date(a.triggered_at);
      const bDate = new Date(b.triggered_at);
      return aDate.getTime() - bDate.getTime();
    });

    const lastlyCreatedPod = tempPods[this.pods.length - 1];

    return lastlyCreatedPod;
  }

  get hasFinished(): boolean {
    return (
      this.status === GenerationStatus.READY ||
      this.status === GenerationStatus.TIMEOUT ||
      this.status === GenerationStatus.FAILED
    );
  }

  get hasSucceeded(): boolean {
    return this.status === GenerationStatus.READY;
  }

  get shouldBePolled(): boolean {
    return (
      this.status === GenerationStatus.PENDING ||
      this.status === GenerationStatus.RUNNING
    );
  }

  get sourceTag(): string {
    const splittedSource = this.source.split(':');
    const gitLabTag = splittedSource[splittedSource.length - 1];
    return gitLabTag;
  }

  get duration(): string {
    const endedAt = this.targetedPod?.ended_at
      ? new Date(this.targetedPod.ended_at)
      : this.timedOutAt
        ? new Date(this.timedOutAt)
        : new Date();
    const triggeredAt = new Date(this.createdAt);
    const duration = endedAt.getTime() - triggeredAt.getTime();
    const durationInSeconds = Math.floor(duration / 1000);

    return this.formatSecondsToHumanDuration(durationInSeconds);
  }

  get elapsedTime(): string {
    const now = new Date();
    const timeElapsed = now.getTime() - new Date(this.createdAt).getTime();
    const timeElapsedInSeconds = Math.floor(timeElapsed / 1000);

    return this.formatSecondsToHumanDuration(timeElapsedInSeconds).concat(
      ' ago'
    );
  }

  get status(): GenerationStatus {
    if (this.pods.length != 0) {
      return this.podStatusToGenerationStatus(this.targetedPod?.status);
    }

    if (this.hasTimedOut) {
      return GenerationStatus.TIMEOUT;
    }

    return GenerationStatus.PENDING;
  }

  private get hasTimedOut(): boolean {
    const createdAt = new Date(
      this.targetedPod?.triggered_at ?? this.createdAt
    );
    const now = new Date();
    const timeElapsed = now.getTime() - createdAt.getTime();
    const timeElapsedInSeconds = Math.floor(timeElapsed / 1000);

    return timeElapsedInSeconds > POD_TIMEOUT_THRESHOLD_IN_SECONDS;
  }

  /* --------------------------------- Helpers -------------------------------- */
  private formatSecondsToHumanDuration(duration: number): string {
    if (duration < UNITS_TO_SECONDS.MINUTE) {
      return `${duration}s`;
    } else if (duration < UNITS_TO_SECONDS.HOUR) {
      return `${Math.floor(duration / UNITS_TO_SECONDS.MINUTE)}m`;
    } else if (duration < UNITS_TO_SECONDS.DAY) {
      return `${Math.floor(duration / UNITS_TO_SECONDS.HOUR)}h`;
    } else if (duration < UNITS_TO_SECONDS.MONTH) {
      return `${Math.floor(duration / UNITS_TO_SECONDS.DAY)}d`;
    } else if (duration < UNITS_TO_SECONDS.YEAR) {
      return `${Math.floor(duration / UNITS_TO_SECONDS.MONTH)}mo`;
    } else {
      return `${Math.floor(duration / UNITS_TO_SECONDS.YEAR)}y`;
    }
  }

  private podStatusToGenerationStatus(status?: PodStatus): GenerationStatus {
    switch (status) {
      case 'RUNNING':
        return GenerationStatus.RUNNING;
      case 'FAILURE':
        return GenerationStatus.FAILED;
      case 'SUCCESS':
        return GenerationStatus.READY;
      default:
        return GenerationStatus.PENDING;
    }
  }

  /* ------------------------- Abstract class methods ------------------------- */
  get toJSON() {
    return {
      currentlyDeployed: this.currentlyDeployed,
      timedOutAt: this.timedOutAt
    };
  }

  get isDeletable(): boolean {
    return false;
  }

  get errors(): ModelError[] {
    return [];
  }
}
