import { CustomCellRendererProps } from 'ag-grid-react';
import {
  AtomReference,
  HydratedRowAtomReference
} from 'shared/src/atom/atomReference.schema';

import { GroupedBadges } from '@components/database/groupedBadges';

import { useHydratedRowAtomReference } from '@hooks/useHydratedRowAtomReference';

export const BadgeRenderer = (
  params: CustomCellRendererProps<any, AtomReference[] | undefined>
) => {
  // TODO: find a working way to simulate focus/edit state to trigger tooltip
  // useEffect(() => {
  //   if (params.node.rowIndex !== null && params.column?.getId() !== undefined) {
  //     params.api.setFocusedCell(params.node.rowIndex, params.column.getId());
  //   }
  // }, []);

  const hydratedReferences: HydratedRowAtomReference[] = [];

  if (params.value) {
    hydratedReferences.push(...useHydratedRowAtomReference(params.value));
  }

  const selectedOptions = hydratedReferences.map((hydratedRef) => {
    return {
      label: hydratedRef.displayedValue,
      value: hydratedRef.dataItemId
    };
  });

  return <GroupedBadges selectedOptions={selectedOptions} />;
};
