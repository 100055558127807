import { useState } from 'react';

import { camelCase } from 'camel-case';
import { Plus } from 'lucide-react';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { ResolvedVariableType } from 'shared/src/atom/variables';
import {
  CellDataType,
  Columns,
  Database as CreateDatabaseDTO
} from 'shared/src/database/database.schema';
import { TraceKeyMode } from 'shared/src/other/traceKey.schema';

import { LoaderBox, LoaderContainer } from '@pages/Editor/editor.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { BasicModal } from '@atoms/modal';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ParamsList } from '@/routes/routes.types';
import nanoID from '@/utils/nanoID';
import { Button, CircularProgress, DialogActions, IconButton } from '@mui/joy';

import { DatabasesContainer } from './database.style';

const programsRowData = [
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'ID/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'CH/CREDIT ANVIL/QS',
    proportional: true
  },
  {
    name: 'CH/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'CH/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'CH/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'CH/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'SGR CH/RCA RCG/XL',
    proportional: false
  },
  {
    name: 'SGR CH/ACCIDENTS /XL',
    proportional: false
  },
  {
    name: 'CH/CYBER/QS',
    proportional: true
  },
  {
    name: 'CH/PTY AND MOTOR HULL CAT/XL',
    proportional: false
  },
  {
    name: 'CH/MTPL MOTOR PA/XL',
    proportional: false
  },
  {
    name: 'CH/CREDIT BOND/ XL',
    proportional: false
  },
  {
    name: 'AXL/BOND/QS',
    proportional: true
  },
  {
    name: 'AXL/GENERAL AVIATION /QS',
    proportional: true
  },
  {
    name: 'AXL/EDP MONDE ENTIER/SP',
    proportional: true
  },
  {
    name: 'AXL/MARINE YACHT/QS',
    proportional: true
  },
  {
    name: 'AXL/AVIATION HULL WAR/QS',
    proportional: true
  },
  {
    name: 'AXL/AVIATION/QS',
    proportional: true
  },
  {
    name: 'AXL/GENERAL AVIATION WHOLE ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/GENERAL AVIATION HULL ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/COMBINE MARINE AVIATION/XL',
    proportional: false
  },
  {
    name: 'AXL/SMALL AVIATION SUPPLIERS/QS',
    proportional: true
  },
  {
    name: 'AXL/MARINE WHOLE ACCOUNT/XL',
    proportional: false
  },
  {
    name: 'AXL/CARGO/ XL',
    proportional: false
  },
  {
    name: 'AXL/ MARINE WAR CCR / QS',
    proportional: true
  },
  {
    name: 'AXL/MARINE WAR/SL',
    proportional: false
  },
  {
    name: 'AXL/MARINE YACHT/SP',
    proportional: true
  },
  {
    name: 'AXL/EMA EMMLA/ XL',
    proportional: false
  },
  {
    name: 'AXL/SPACE/QS',
    proportional: true
  },
  {
    name: 'AXL/CAR EAR/XL',
    proportional: false
  },
  {
    name: 'AXL/CAR EAR /SP',
    proportional: true
  },
  {
    name: 'AXL/CYBER/QS',
    proportional: true
  },
  {
    name: 'AXL/D&O/QS',
    proportional: true
  },
  {
    name: 'AXL/DECENNIAL RISK/XL',
    proportional: false
  },
  {
    name: 'AXL/ENERGY & CHEMICALS/QS',
    proportional: true
  },
  {
    name: 'AXL/ENERGY & CHEMICALS/XL',
    proportional: false
  },
  {
    name: 'AXL/CASUALTY RISK AND EVENT/XL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR & NON MOTOR NAT CAT CCR/QS',
    proportional: true
  },
  {
    name: 'AXL/MOTOR & NON MOTOR NAT CAT CCR/SL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR THIRD PARTY LIABILITY/XL',
    proportional: false
  },
  {
    name: 'AXL / PARAMETRIC/SL',
    proportional: false
  },
  {
    name: 'AXL/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AXL/PROPERTY NET FREQUENCY PROTECTION/XL',
    proportional: false
  },
  {
    name: 'AXL/FINANCIAL LINES/QS',
    proportional: true
  },
  {
    name: 'AXL/AXA GI CANADIAN FRONTING/QS',
    proportional: true
  },
  {
    name: 'AXL/CAT AGGREGATE/XL',
    proportional: false
  },
  {
    name: 'AXL/CAT INSURANCE TOWER/XL',
    proportional: false
  },
  {
    name: 'AXL/GROUP PA/XL',
    proportional: false
  },
  {
    name: 'AXL/MOTOR DAMAGE/XL',
    proportional: false
  },
  {
    name: 'AXL/PARAMETRIC/QS SP',
    proportional: true
  },
  {
    name: 'AXL/PROPERTY CAT TOP AND DROP/XL',
    proportional: false
  },
  {
    name: 'AXL/CEDED RE PROPERTY/QS',
    proportional: true
  },
  {
    name: 'AXL/TERRORISME CCR/SL',
    proportional: false
  },
  {
    name: 'CO/BANCOLOMBIA PROPERTY CAT/QS',
    proportional: true
  },
  {
    name: 'CO/BONDS/QS',
    proportional: true
  },
  {
    name: 'CO/ENGINEERING/QS',
    proportional: true
  },
  {
    name: 'CO/SPECIALTY LINES TPL/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'CO/BONDS/XL',
    proportional: false
  },
  {
    name: 'CO/BANCO COLPATRIA CAT/QS',
    proportional: true
  },
  {
    name: 'CO/CAT FONDO NACIONAL DE AHORRO/QS',
    proportional: true
  },
  {
    name: 'CO/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'CO/TPL/XL',
    proportional: false
  },
  {
    name: 'CO/WORKERS COMPENSATION/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM CO/MARINE/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM CO/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'CO/MARINE/QS',
    proportional: true
  },
  {
    name: 'CO/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'CO/LIFE/SP',
    proportional: true
  },
  {
    name: 'CO/LIFE GROUP/XL',
    proportional: false
  },
  {
    name: 'CO/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'TR/ENGINEERING FIRE/QS',
    proportional: true
  },
  {
    name: 'TR/CAR EAR FIRE/SP',
    proportional: true
  },
  {
    name: 'TR/GENERAL ACCIDENT/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TR/HOUSEHOLDERS/QS',
    proportional: true
  },
  {
    name: 'TR/ENGINEERING NON MOTOR ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'TR/HAZARDOUS MATERIAL/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM TR/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'TR/MOTOR EXTENDED WARRANTY/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM SE TR/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TR/PA/SP',
    proportional: true
  },
  {
    name: 'TR/LIFE/SP',
    proportional: true
  },
  {
    name: 'ES/BOND/XL',
    proportional: false
  },
  {
    name: 'ES/BOND/QS',
    proportional: true
  },
  {
    name: 'ES/CYBER RISKS/QS',
    proportional: true
  },
  {
    name: 'ES SE/MARINE/XL',
    proportional: false
  },
  {
    name: 'ES/DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'SE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'ES/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ES/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ES SE/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ES/MTPL/XL',
    proportional: false
  },
  {
    name: 'ES/GTPL/XL',
    proportional: false
  },
  {
    name: 'ES/GTPL PUBLIC ADMINISTRATION/XL',
    proportional: false
  },
  {
    name: 'ES/LIFE PA PROFESSIONAL SPORT PLAYERS/XL',
    proportional: false
  },
  {
    name: 'ES/CAT CLIMATE EVENTS/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE REGIONAL/XL',
    proportional: false
  },
  {
    name: 'ES/LIFE INDIVIDUAL/SP',
    proportional: true
  },
  {
    name: 'ES/LIFE GROUP/SP',
    proportional: true
  },
  {
    name: 'ES/LIFE LTC/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM MX/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MX/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MX/MARINE/XL',
    proportional: false
  },
  {
    name: 'MX/MISCELLANEOUS/XL',
    proportional: false
  },
  {
    name: 'MX/MOTOR/XL',
    proportional: false
  },
  {
    name: 'MX/GTPL/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MX/CARGO/SP',
    proportional: true
  },
  {
    name: 'MX/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'MX/HULL/QS',
    proportional: true
  },
  {
    name: 'MX/AVIATION/XL',
    proportional: false
  },
  {
    name: 'MX/FINANCIAL LINES/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MX/CAT OVERSEAS/XL',
    proportional: false
  },
  {
    name: 'MX/CYBER/QS',
    proportional: true
  },
  {
    name: 'MX/RESIDENTIAL/QS',
    proportional: true
  },
  {
    name: 'MX/TENT PLAN/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE GROUP/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE MEDEX/XL',
    proportional: false
  },
  {
    name: 'MX/LIFE INDIVIDUAL/SP',
    proportional: true
  },
  {
    name: 'MX/FRONTING AMEXCO/QS',
    proportional: true
  },
  {
    name: 'MX/LIFE GROUP/SP',
    proportional: true
  },
  {
    name: 'MX/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'MY/BOND/QS',
    proportional: true
  },
  {
    name: 'MY/LIABILITY/XL',
    proportional: false
  },
  {
    name: 'MY/MARINE/XL',
    proportional: false
  },
  {
    name: 'MY/PA/XL',
    proportional: false
  },
  {
    name: 'MY/PROPERTY RISK CAT/XL',
    proportional: false
  },
  {
    name: 'MY/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'MY/UMBRELLA/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'HK/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/XL',
    proportional: false
  },
  {
    name: 'ASIA/BOND/QS SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'ASIA PH/BOND/QS',
    proportional: true
  },
  {
    name: 'ASIA PH/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'PH/LIFE INDIVIDUAL SP',
    proportional: true
  },
  {
    name: 'PH/LIFE GROUP SP',
    proportional: true
  },
  {
    name: 'PH/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'TH/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/MARINE/SP',
    proportional: true
  },
  {
    name: 'ASIA/MARINE/XL',
    proportional: false
  },
  {
    name: 'ASIA/MOTOR LIABILITY/XL',
    proportional: false
  },
  {
    name: 'ASIA/PA/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'ASIA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'ASIA/PROPERTY/SP QS',
    proportional: true
  },
  {
    name: 'ASIA/COMBINED CASUALTY PA /XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'JP/MOTOR/QS',
    proportional: true
  },
  {
    name: 'JP/MTPL incl PA/XL',
    proportional: false
  },
  {
    name: 'JP/MOD CAT/XL',
    proportional: false
  },
  {
    name: 'JP/LIFE INDIVIDUAL SUW/QS',
    proportional: true
  },
  {
    name: 'JP/LIFE EPIDEMIC AND PANDEMIC/XL',
    proportional: false
  },
  {
    name: 'KR/MOTOR/QS',
    proportional: true
  },
  {
    name: 'KR/MOTOR/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'DZ/MARINE CARGO/QS',
    proportional: true
  },
  {
    name: 'DZ/MARINE CARGO/XL',
    proportional: false
  },
  {
    name: 'DZ/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'DZ/PROPERTY RISK/ QS SP',
    proportional: true
  },
  {
    name: 'DZ/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'BE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'BE/ENGINEERING/SP',
    proportional: true
  },
  {
    name: 'BE/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BE/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'BE/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'BE/CASUALTY/QS',
    proportional: true
  },
  {
    name: 'SGR BE/CONSTRUCTION/SP',
    proportional: true
  },
  {
    name: 'SGR BE/RCA RCG/XL',
    proportional: false
  },
  {
    name: 'SGR BE/ACCIDENTS/XL',
    proportional: false
  },
  {
    name: 'BE/CYBER/QS',
    proportional: true
  },
  {
    name: 'BE/LIFE/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LB/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'LB/NON MARINE/QS SP',
    proportional: true
  },
  {
    name: 'REGIONAL INM LB/PROPERTY RISK CAT/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LB/GROUP INDIVIDUAL LIFE / SP',
    proportional: true
  },
  {
    name: 'TIP/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'DE/MOTOR/QS',
    proportional: true
  },
  {
    name: 'DE/PROPERTY RISK/QS',
    proportional: true
  },
  {
    name: 'DE/ENGINEERING/QS',
    proportional: true
  },
  {
    name: 'DE/MARINE/QS',
    proportional: true
  },
  {
    name: 'DE/CYBER RISK/QS',
    proportional: true
  },
  {
    name: 'DE/SURETY/XL',
    proportional: false
  },
  {
    name: 'DE/GTPL/XL',
    proportional: false
  },
  {
    name: 'DE/MARINE AVIATION/XL',
    proportional: false
  },
  {
    name: 'DE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'DE/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'DE/SICHER MOTOR/XL',
    proportional: false
  },
  {
    name: 'DE/MTPL MOTOR PAPA/XL',
    proportional: false
  },
  {
    name: 'DE/SEMI AUTO FAC/XL',
    proportional: false
  },
  {
    name: 'BE/MOD CAT/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/ PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MA/CREDIT/QS',
    proportional: true
  },
  {
    name: 'MA/CAR EAR DECENNIAL/QS SP',
    proportional: true
  },
  {
    name: 'MA/MTPL GTPL PA WC/XL',
    proportional: false
  },
  {
    name: 'MA/ PROPERTY CAT /XL',
    proportional: false
  },
  {
    name: 'MA/PROPERTY ENGINEERING MARINE/ XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM MA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MA/MARINE/XL',
    proportional: false
  },
  {
    name: 'MA/MARINE/QS',
    proportional: true
  },
  {
    name: 'MA/LIFE GROUP INDIVIDUAL/XL',
    proportional: false
  },
  {
    name: 'MA/LIFE GROUP INDIVIDUAL/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'EG/NON MARINE MARINE PROPERTY RISK-CAT/XL',
    proportional: false
  },
  {
    name: 'EG/NON MARINE MARINE PROPERTY/QS SP',
    proportional: true
  },
  {
    name: 'BOOSTER/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'EG/INDIVIDUAL AND GROUP LIFE/SP',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM GF SA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GF SA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'GF SA/PROPERTY RISK-CAT SUBLAYER/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM OR/MARINE/XL',
    proportional: false
  },
  {
    name: 'GF SA/CASUALTY/XL',
    proportional: false
  },
  {
    name: 'DE/LIFE ALV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE DBV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE DAV/SP',
    proportional: true
  },
  {
    name: 'DE/LIFE CAT ALV/XL',
    proportional: false
  },
  {
    name: 'DE/UNIT LINKED CONTRACT ALV/QS',
    proportional: true
  },
  {
    name: 'DE/UNIT LINKED CONTRACT DAV/QS',
    proportional: true
  },
  {
    name: 'REGIONAL INM GR/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GR/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM GR/GTPL MTPL PA AND CASUALTY/XL',
    proportional: false
  },
  {
    name: 'GR SE/MARINE/XL',
    proportional: false
  },
  {
    name: 'SE/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'SE/MARINE/QS',
    proportional: true
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'GR/CYBER RISKS/QS ',
    proportional: true
  },
  {
    name: 'GR/ALP LIFE/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'LU/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'LU/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'REGIONAL INM LU/MTPL GTPL CASUALTY/XL',
    proportional: false
  },
  {
    name: 'LU/LIFE/SP',
    proportional: true
  },
  {
    name: 'LU/LIFE/XL SL',
    proportional: false
  },
  {
    name: 'IT SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'IT/GTPL/XL',
    proportional: false
  },
  {
    name: 'IT/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'IT/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'IT SE/ENGINEERING DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'IT/HAIL/SL',
    proportional: false
  },
  {
    name: 'IT/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'IT/PROFESSIONAL LIABILITY/QS',
    proportional: true
  },
  {
    name: 'IT/BONDS/QS',
    proportional: true
  },
  {
    name: 'IT/BONDS/XL',
    proportional: false
  },
  {
    name: 'IT/MARINE/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'SGR IT/ RCA RCG/XL',
    proportional: false
  },
  {
    name: 'IT/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'IT/CYBER/QS',
    proportional: true
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'IT/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'IT/LIFE/XL',
    proportional: false
  },
  {
    name: 'MPS SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'MPS/GTPL/XL',
    proportional: false
  },
  {
    name: 'MPS/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'MPS/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'MPS/ENGINEERING DECENNIAL/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS',
    proportional: true
  },
  {
    name: 'MPS/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'MPS/PERSONAL ACCIDENT/XL',
    proportional: false
  },
  {
    name: 'SE/LIFE/XL',
    proportional: false
  },
  {
    name: 'MPS/LIFE/XL',
    proportional: false
  },
  {
    name: 'QUIXA SE/MTPL/XL',
    proportional: false
  },
  {
    name: 'QUIXA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'QUIXA/ATMOSPHERIC EVENTS AGG CAT/XL',
    proportional: false
  },
  {
    name: 'QUIXA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'QUIXA/GTPL/XL',
    proportional: false
  },
  {
    name: 'AF/BOND/QS',
    proportional: true
  },
  {
    name: 'AF/CAUTION CREDIT ACQUEREURS/XL',
    proportional: false
  },
  {
    name: 'AF/CYBER/QS',
    proportional: true
  },
  {
    name: 'AF/CAT NAT AUTO/SL',
    proportional: false
  },
  {
    name: 'AF/CAT NAT AUTO NON AUTO/QS',
    proportional: true
  },
  {
    name: 'AF/CAT NAT NON AUTO/SL',
    proportional: false
  },
  {
    name: 'AF/TERROR WAR/SL',
    proportional: false
  },
  {
    name: 'AF/TERROR WAR/QS',
    proportional: true
  },
  {
    name: 'AF/CONSTRUCTION/QS',
    proportional: true
  },
  {
    name: 'AF/CONSTRUCTION/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'AF/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'AF/CAR EAR/XL',
    proportional: false
  },
  {
    name: 'AF/GTPL/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL/QS',
    proportional: true
  },
  {
    name: 'AF/INLAND TRANSPORT/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL<1998/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL 1998 to 2004/XL',
    proportional: false
  },
  {
    name: 'AF/MTPL>2004/XL',
    proportional: false
  },
  {
    name: 'SGR/ CONSTRUCTION/XL',
    proportional: false
  },
  {
    name: 'SGR/ PROPERTY/XL',
    proportional: false
  },
  {
    name: 'SGR/ RCA RCG/XL',
    proportional: false
  },
  {
    name: 'AUTOFAC/PROPERTY/QS XL',
    proportional: true
  },
  {
    name: 'AF/CREDITOR MOTOR GAP NAT CAT/XL',
    proportional: false
  },
  {
    name: 'AF/GROUP PA PER EVENT/XL',
    proportional: false
  },
  {
    name: 'FR/LIFE/XL SL',
    proportional: false
  },
  {
    name: 'FR/LIFE/XP',
    proportional: true
  },
  {
    name: 'MSC/CAT NAT AUTO NON AUTO/QS',
    proportional: true
  },
  {
    name: 'MSC/CAT NAT AUTO NON AUTO/SL',
    proportional: false
  },
  {
    name: 'MSC/TERRORISM/SL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'MSC/PROPERTY RISK XL',
    proportional: false
  },
  {
    name: 'MSC/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'MSC/CYBER RISKS/QS',
    proportional: true
  },
  {
    name: 'MSC/LIFE/XL',
    proportional: false
  },
  {
    name: 'FR DA/MTPL GTPL/XL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY CAT/XL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'FR DA/CAT NAT (CCR)/QS',
    proportional: true
  },
  {
    name: 'FR DA/CAT NAT (CCR)/SL',
    proportional: false
  },
  {
    name: 'FR DA/PROPERTY CAT AGGREGATE GI/XL',
    proportional: false
  },
  {
    name: 'AA IPA/TRAVEL AND LEGAL PROTECTION/XL',
    proportional: false
  },
  {
    name: 'AA IPA/EXPATRIATES COVER/QS',
    proportional: true
  },
  {
    name: 'AA IPA/CEP COVER/QS',
    proportional: true
  },
  {
    name: 'BR/MARINE/XL',
    proportional: false
  },
  {
    name: 'BR/SME/QS',
    proportional: true
  },
  {
    name: 'BR/SURETY BONDS/QS',
    proportional: true
  },
  {
    name: 'BR/SURETY BONDS/XL',
    proportional: false
  },
  {
    name: 'BR/TPL/XL',
    proportional: false
  },
  {
    name: 'BR/PROPERTY ENGINEERING FACILITY/QS',
    proportional: true
  },
  {
    name: 'BR/MULTI PROPERTY/XL',
    proportional: false
  },
  {
    name: 'BR/CYBER/QS',
    proportional: true
  },
  {
    name: 'BR/LIFE CAT/XL',
    proportional: false
  },
  {
    name: 'BR/LIFE TRAVEL/XL',
    proportional: false
  },
  {
    name: 'DE/LEGAL INDUSTRIAL XL',
    proportional: false
  },
  {
    name: 'PT/GTPL/XL',
    proportional: false
  },
  {
    name: 'PT/MTPL/XL',
    proportional: false
  },
  {
    name: 'PT/ENGINEERING/XL',
    proportional: false
  },
  {
    name: 'PT/WORKERS COMPENSATION/XL',
    proportional: false
  },
  {
    name: 'PT/BONDS/QS',
    proportional: true
  },
  {
    name: 'PT/ENGINEERING/XP QS',
    proportional: true
  },
  {
    name: 'PT/MARINE/QS',
    proportional: true
  },
  {
    name: 'PT/MARINE/XL',
    proportional: false
  },
  {
    name: 'PT/PROPERTY RISK/XL',
    proportional: false
  },
  {
    name: 'PT/PROPERTY CAT/XL',
    proportional: false
  }
];

const uniqueProgramsRowData = programsRowData.filter(
  (program, index, self) =>
    index === self.findIndex((p) => p.name === program.name)
);

const buidsRowData = [
  {
    BUID: '2789'
  },
  {
    BUID: '2810'
  },
  {
    BUID: '2815'
  },
  {
    BUID: '2839'
  },
  {
    BUID: '2994'
  },
  {
    BUID: '3078'
  },
  {
    BUID: '3080'
  },
  {
    BUID: '3139'
  },
  {
    BUID: '3351'
  },
  {
    BUID: '3352'
  },
  {
    BUID: '3353'
  },
  {
    BUID: '3436'
  },
  {
    BUID: '3667'
  },
  {
    BUID: '3668'
  },
  {
    BUID: '3804'
  },
  {
    BUID: '3805'
  },
  {
    BUID: '3820'
  },
  {
    BUID: '3825'
  },
  {
    BUID: '3834'
  },
  {
    BUID: '3835'
  },
  {
    BUID: '3842'
  },
  {
    BUID: '3861'
  },
  {
    BUID: '3863'
  },
  {
    BUID: '3873'
  },
  {
    BUID: '3882'
  },
  {
    BUID: '3886'
  },
  {
    BUID: '3921'
  },
  {
    BUID: '3934'
  },
  {
    BUID: '3942'
  },
  {
    BUID: '3966'
  },
  {
    BUID: '3982'
  },
  {
    BUID: '4039'
  },
  {
    BUID: '4103'
  },
  {
    BUID: '4113'
  },
  {
    BUID: '4224'
  },
  {
    BUID: '4231'
  },
  {
    BUID: '4232'
  },
  {
    BUID: '4234'
  },
  {
    BUID: '4244'
  },
  {
    BUID: '4257'
  },
  {
    BUID: '4303'
  },
  {
    BUID: '4313'
  },
  {
    BUID: '4325'
  },
  {
    BUID: '4326'
  },
  {
    BUID: '4327'
  },
  {
    BUID: '4328'
  },
  {
    BUID: '4329'
  },
  {
    BUID: '4333'
  },
  {
    BUID: '4334'
  },
  {
    BUID: '4336'
  },
  {
    BUID: '4337'
  },
  {
    BUID: '4339'
  },
  {
    BUID: '4827'
  },
  {
    BUID: '4926'
  },
  {
    BUID: '4930'
  },
  {
    BUID: '5120'
  },
  {
    BUID: '5327'
  },
  {
    BUID: '5341'
  },
  {
    BUID: '5342'
  },
  {
    BUID: '5354'
  },
  {
    BUID: '5360'
  },
  {
    BUID: '5438'
  },
  {
    BUID: '5443'
  },
  {
    BUID: '5474'
  },
  {
    BUID: '5538'
  },
  {
    BUID: '5556'
  },
  {
    BUID: '5560'
  },
  {
    BUID: '5562'
  },
  {
    BUID: '5563'
  },
  {
    BUID: '5567'
  },
  {
    BUID: '5571'
  },
  {
    BUID: '5572'
  },
  {
    BUID: '5582'
  },
  {
    BUID: '5584'
  },
  {
    BUID: '5773'
  },
  {
    BUID: '5817'
  },
  {
    BUID: '5824'
  },
  {
    BUID: '5831'
  },
  {
    BUID: '5927'
  },
  {
    BUID: '6059'
  },
  {
    BUID: '6061'
  },
  {
    BUID: '6109'
  },
  {
    BUID: '6110'
  },
  {
    BUID: '6111'
  },
  {
    BUID: '6407'
  },
  {
    BUID: '6546'
  },
  {
    BUID: '7294'
  },
  {
    BUID: '7598'
  },
  {
    BUID: '8031'
  },
  {
    BUID: '8413'
  },
  {
    BUID: '8479'
  },
  {
    BUID: '8499'
  },
  {
    BUID: '8567'
  },
  {
    BUID: '8855'
  },
  {
    BUID: '9751'
  },
  {
    BUID: '9771'
  },
  {
    BUID: '10001'
  },
  {
    BUID: '10101'
  },
  {
    BUID: '10411'
  },
  {
    BUID: '10439'
  },
  {
    BUID: '10524'
  },
  {
    BUID: '10823'
  },
  {
    BUID: '10878'
  },
  {
    BUID: '10884'
  },
  {
    BUID: '10911'
  },
  {
    BUID: '10913'
  },
  {
    BUID: '10914'
  },
  {
    BUID: '10935'
  },
  {
    BUID: '10942'
  },
  {
    BUID: '11000'
  },
  {
    BUID: '11004'
  },
  {
    BUID: '11071'
  },
  {
    BUID: '11080'
  },
  {
    BUID: '11101'
  },
  {
    BUID: '11128'
  },
  {
    BUID: '11134'
  },
  {
    BUID: '11158'
  },
  {
    BUID: '11252'
  },
  {
    BUID: '11865'
  },
  {
    BUID: '20002'
  },
  {
    BUID: '30003'
  }
];

const uniqueBuidRowData = buidsRowData.filter(
  (buid, index, self) => index === self.findIndex((b) => b.BUID === buid.BUID)
);

const entitiesRowData = [
  {
    name: 'AXA MANDIRI /ID',
    label: 'mandiri'
  },
  {
    name: 'AXA LIFE MANDIRI /ID',
    label: 'mandiriLife'
  },
  {
    name: 'AXA SWITZERLAND /CH',
    label: 'winterthur'
  },
  {
    name: 'AXA XL/AXL',
    label: 'xl'
  },
  {
    name: 'AXA COLOMBIA /CO',
    label: 'colombia'
  },
  {
    name: 'AXA LIFE COLOMBIA /CO',
    label: 'colombiaLife'
  },
  {
    name: 'AXA TURKEY /TR',
    label: 'turkey'
  },
  {
    name: 'AXA LIFE TURKEY /TR',
    label: 'turkeyLife'
  },
  {
    name: 'AXA SPAIN /ES',
    label: 'seguros'
  },
  {
    name: 'AXA LIFE SPAIN',
    label: 'spainLife'
  },
  {
    name: 'AXA MEXICO /MX',
    label: 'mexico'
  },
  {
    name: 'AXA LIFE MEXICO /MX',
    label: 'mexicoLife'
  },
  {
    name: 'AXA MALAYSIA /MY',
    label: 'malaysia'
  },
  {
    name: 'AXA HONG-KONG /HK',
    label: 'hongkong'
  },
  {
    name: 'AXA LIFE HONG-KONG /HK',
    label: 'hongkongLife'
  },
  {
    name: 'AXA SINGAPORE /SG',
    label: 'singapore'
  },
  {
    name: 'AXA PHILIPPINES /PH',
    label: 'philippines'
  },
  {
    name: 'AXA LIFE PHILIPPINES /PH',
    label: 'philippinesLife'
  },
  {
    name: 'AXA THAILAND /TH',
    label: 'thailand'
  },
  {
    name: 'AXA LIFE THAILAND /TH',
    label: 'thailandLife'
  },
  {
    name: 'AXA TIANPING /TN',
    label: 'tianping'
  },
  {
    name: 'AGD JAPAN',
    label: 'japan'
  },
  {
    name: 'AXA LIFE JAPAN /JP',
    label: 'japanLife'
  },
  {
    name: 'AGD KOREA',
    label: 'korea'
  },
  {
    name: 'AXA ALGERIE /DZ',
    label: 'algeria'
  },
  {
    name: 'AXA BELGIQUE /BE',
    label: 'belgium'
  },
  {
    name: 'AXA LIFE BELGIQUE /BE',
    label: 'lifeBelgium'
  },
  {
    name: 'AXA MIDDLE EAST /LB',
    label: 'middleEast'
  },
  {
    name: 'AXA LIFE MIDDLE EAST /LB',
    label: 'middleEastLife'
  },
  {
    name: 'AXA GERMANY /DE',
    label: 'versicherung'
  },
  {
    name: 'YUZZU /BE',
    label: 'yuzzu'
  },
  {
    name: 'AXA MAROC /MA',
    label: 'maroc'
  },
  {
    name: 'AXA LIFE MAROC /MA',
    label: 'marocLife'
  },
  {
    name: 'AXA EGYPT /EG',
    label: 'egypt'
  },
  {
    name: 'AXA LIFE EGYPT /EG',
    label: 'egyptLife'
  },
  {
    name: 'AXA GULF and AXA COOPERATIVE',
    label: 'gulfCooperative'
  },
  {
    name: 'AXA LIFE GERMANY /DE',
    label: 'lebensversicherung'
  },
  {
    name: 'AXA INSURANCE GREECE /GR',
    label: 'greeceInsurance'
  },
  {
    name: 'AXA LIFE INSURANCE GREECE /GR',
    label: 'greeceInsuranceLife'
  },
  {
    name: 'AXA LUXEMBOURG /LU',
    label: 'luxembourg'
  },
  {
    name: 'AXA LIFE LUXEMBOURG',
    label: 'luxembourgLife'
  },
  {
    name: 'AXA ITALY /IT',
    label: 'italy'
  },
  {
    name: 'AXA LIFE ITALY /IT',
    label: 'italyLife'
  },
  {
    name: 'AXA MPS ITALY /IT',
    label: 'italyMps'
  },
  {
    name: 'AXA LIFE MPS ITALY /IT',
    label: 'italyMpsLife'
  },
  {
    name: 'QUIXA ITALY /IT',
    label: 'italyQuixa'
  },
  {
    name: 'AXA FRANCE/FR',
    label: 'france'
  },
  {
    name: 'AXA Life France /FR',
    label: 'franceLife'
  },
  {
    name: 'MUTUELLE ST CHRISTOPHE /FR',
    label: 'mutuelleSaintChristophe'
  },
  {
    name: 'MUTUELLE ST CHRISTOPHE LIFE /FR',
    label: 'mutuelleSaintChristopheLife'
  },
  {
    name: 'AXA France (ex AVANSSUR) /FR DA',
    label: 'franceAvanssur'
  },
  {
    name: 'AXA ASSISTANCE /FR',
    label: 'assistance'
  },
  {
    name: 'AXA BRASIL /BR',
    label: 'brasil'
  },
  {
    name: 'AXA LIFE BRASIL/BR',
    label: 'brasilLife'
  },
  {
    name: 'ROLAND RECHTSSCHUTZ /DE',
    label: 'roland'
  },
  {
    name: 'AGEAS ex AXA Portugal',
    label: 'ageas'
  }
];

const DEFAULT_PROGRAMS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'proportional',
    cellDataType: CellDataType.Boolean
  }
];

const DEFAULT_ENTITIES_COLUMNS: Columns = [
  {
    field: 'label',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      editableAfterGeneration: false,
      regex: '^[a-zA-Z]+$'
    }
  },
  {
    field: 'name',
    cellDataType: CellDataType.Text
  },
  {
    field: 'programs',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'ZH8kt56'
      }
    }
  },
  {
    field: 'BUIDS',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'RjtRTwz'
      }
    }
  }
];

const DEFAULT_BUIDS_COLUMNS: Columns = [
  {
    field: 'BUID',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  }
];

const Databases = () => {
  const navigate = useNavigate();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const process = useProcess();
  const { databaseStore, atomStore } = useStores();
  const [showNewRepoInput, setShowNewRepoInput] = useState(false);
  const [newDatabaseName, setNewDatabaseName] = useState('');
  const [firstDataModalOpen, setFirstDataModalOpen] = useState<boolean>(false);
  let currentTab: string = '';

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  // useEffect(() => {
  //   const databases = process.databases;

  //   if (databases.length == 0 && databaseId) {
  //     navigate('');
  //   }

  //   if (databases.length > 0 && !databaseId) {
  //     navigate(`${databases[0].id}`);
  //     currentTab = databases[0].id;
  //   }

  //   if (databaseId) {
  //     navigate(`${databaseId}`);
  //     currentTab = databaseId;
  //   }
  // }, []);

  const onTabChange = (newTabId: string) => {
    currentTab = newTabId;
    navigate(`${newTabId}`);
  };

  const isUserOwner = () => {
    return process.getPermission().can_delete;
  };

  const createNewRowAtom = (gridAtomId: string, data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          elementId: gridAtomId,
          parentId: gridAtomId,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: 'Row' as ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  const createNewDatabase = async (name: string) => {
    setShowNewRepoInput(false);
    setFirstDataModalOpen(false);

    if (!newDatabaseName) return;

    const newGridAtomId = nanoID();

    const columns =
      name === 'Entities'
        ? DEFAULT_ENTITIES_COLUMNS
        : name === 'Programs'
          ? DEFAULT_PROGRAMS_COLUMNS
          : DEFAULT_BUIDS_COLUMNS;

    const newDatabaseDto: CreateDatabaseDTO = {
      id: newGridAtomId,
      name: newDatabaseName,
      columnDefinitions: columns,
      processId: process?.id ?? '',
      rowReferences: [],
      traceKey: {
        value: camelCase(newDatabaseName),
        mode: TraceKeyMode.Follow
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null
    };

    const newGridModel = await databaseStore.createNewDatabase(newDatabaseDto);

    if (!newGridModel) {
      return;
    }

    databases.push(newGridModel);
    process.setDatabaseIds([...process.getDatabaseIds(), newGridModel.id]);

    setNewDatabaseName('');

    // name: 'AXA France',
    //   programs: [
    //     {
    //       dataItemId: 'vWNxq7t',
    //       blockType: DTRBlocks.Row,
    //       sourceId: 'CjpIlRM'
    //     }
    //   ]

    // name: 'AXA XL',
    //   proportional: true

    if (name === 'Programs') {
      for (const program of uniqueProgramsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: program.name,
          proportional: program.proportional
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);

        await new Promise((r) => setTimeout(r, 500));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 500));
      }
    } else if (name === 'BUIDs') {
      for (const buid of uniqueBuidRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          BUID: buid.BUID
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 500));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 500));
      }
    } else if (name === 'Entities') {
      for (const entity of entitiesRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: entity.name,
          label: entity.label
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 500));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 500));
      }
    }

    onTabChange(newGridModel.id);
  };

  const handleNewRepoKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      createNewDatabase('Entities');
    } else if (event.key == 'Escape') {
      setShowNewRepoInput(false);
    }
  };

  const databases = process?.databases.map((database) => {
    return {
      id: database.id,
      getName: database.getName
    };
  });

  if (databases.length == 0 && databaseId) {
    navigate('');
  }

  if (databases.length > 0 && !databaseId) {
    onTabChange(databases[0].id);
  }

  if (databaseId) {
    currentTab = databaseId;
  }

  return (
    <>
      <DatabasesContainer>
        {databases.length == 0 ? (
          <ActionButton
            value="Create your first database"
            onClick={() => setFirstDataModalOpen(true)}
            sx={{
              width: '250px',
              alignSelf: 'center',
              justifySelf: 'center',
              marginTop: '20px'
            }}
          />
        ) : (
          <Tabs
            value={currentTab}
            onValueChange={onTabChange}
            className="h-full pl-2"
          >
            {databases.map((database) => (
              <TabsContent
                value={database.id}
                key={database.id}
                className="h-93 -mt-0.5"
              >
                <Outlet />
              </TabsContent>
            ))}
            <TabsList className="flex flex-wrap items-center justify-start bg-transparent mt-2">
              {databases.map((databaseModel) => (
                <TabsTrigger value={databaseModel.id} key={databaseModel.id}>
                  {databaseModel.getName}
                </TabsTrigger>
              ))}
              {isUserOwner() &&
                (!showNewRepoInput ? (
                  <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    onClick={() => setShowNewRepoInput(true)}
                    sx={{
                      maxWidth: '100px',
                      marginLeft: '5px'
                    }}
                  >
                    <Plus />
                  </IconButton>
                ) : (
                  <InputField
                    placeholder="Entities"
                    onChange={(e) => setNewDatabaseName(e.target.value)}
                    value={newDatabaseName}
                    width="100px"
                    onBlur={() => setShowNewRepoInput(false)}
                    onKeyDown={handleNewRepoKeyDown}
                    sx={{
                      marginLeft: 1
                    }}
                  />
                ))}
            </TabsList>
          </Tabs>
        )}
      </DatabasesContainer>
      <BasicModal
        open={firstDataModalOpen}
        setOpen={setFirstDataModalOpen}
        title="Give it a name"
        width="400px"
      >
        <InputField
          placeholder="Entities"
          onChange={(e) => setNewDatabaseName(e.target.value)}
          value={newDatabaseName}
          width="200px"
        />
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            size="sm"
            disabled={newDatabaseName.length == 0}
            onClick={() => createNewDatabase('Programs')}
            sx={{
              maxWidth: '100px'
            }}
          >
            Create
          </Button>
        </DialogActions>
      </BasicModal>
    </>
  );
};

export default observer(Databases);
