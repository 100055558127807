import { EventLoaded, EventSchema, EventType } from 'shared';

import EventModel from '@models/event.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';

export default class EventStore extends BaseStore<EventModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, EventModel, 'event');
    this.store_ready = true;
  }

  public async createEvent(
    bpmnId: string,
    eventType: EventType,
    workflowId: string
  ) {
    const createEventDTO = {
      bpmnId,
      eventType,
      workflowId
    };

    const rawResponse = await this.httpWrapper.post<EventModel>(
      '/',
      createEventDTO
    );
    if (!rawResponse) {
      newError(
        'EVENT-DuI98',
        `Error while creating the event with dto ${JSON.stringify(createEventDTO)}`,
        true,
        {
          customMessage: 'Error while creating the event'
        }
      );
      return;
    }

    const newEvent = parseWithZod(EventSchema, rawResponse, 'EVENT-G02iS');

    if (!newEvent) return;
    return this.addEventToStore(newEvent);
  }

  public addEventToStore(event: EventLoaded) {
    const eventModel = new EventModel(this, {
      id: event.bpmn_id
    });

    console.log(eventModel);

    this.set(eventModel.id, eventModel);

    return eventModel;
  }

  public async deleteById(id: string) {
    const el = this.get(id);
    if (!el) {
      newError(
        'EVENT-cXMXX',
        `Cannot find the event (id=${id}) you want to delete`,
        true
      );
      return false;
    }
    return el.delete();
  }
}
