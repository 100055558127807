import { ValueSetterParams } from 'ag-grid-community';

const isNewValueNotEmpty = (params: ValueSetterParams) => {
  return params.newValue && params.newValue.trim() !== '';
};

const isValidPrimaryKeyValue = (params: ValueSetterParams) => {
  return params.colDef.context?.isPrimaryKey
    ? isNewValueNotEmpty(params)
    : true;
};

const isRegexSatisfied = (params: ValueSetterParams) => {
  const regex = new RegExp(params.colDef.context.regex);
  return regex.test(params.newValue);
};

const satisfiesRegex = (params: ValueSetterParams) => {
  return params.colDef.context?.regex ? isRegexSatisfied(params) : true;
};

export const globalColumnValueSetter = (params: ValueSetterParams) => {
  if (!satisfiesRegex(params)) {
    return false;
  }

  if (!isValidPrimaryKeyValue(params)) {
    return false;
  }

  params.data[params.colDef.field ?? ''] = params.newValue;
  return true;
};
