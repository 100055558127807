import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { CreateTransitionEvent } from '@/types/event.types';

export const onTransitionCreated = async (
  event: CreateTransitionEvent,
  rootStore: RootStore,
  processId: string
): Promise<boolean> => {
  // TODO: check if the flow is actually a transition (action -> action)
  // ? probably need a plexer that handles lots of cases in the future

  const transitionId = event.context.connection?.id;

  if (!transitionId) {
    newError('TRAN-02d7b', 'Transition id not found ?', false, {
      description: 'Trying to create a transition which do not have an ID'
    });
    return false; // ? is simply returning a good idea ? the "flow" will be still be saved in the XML
  }

  try {
    const res = await rootStore.transitionStore.createNewTransition(
      transitionId,
      processId
    );
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    newError('TRAN-424d9', e, true, {
      description: 'An error occured while creating the transition'
    });
    return false;
  }
};

export const onTransitionDeleted = async (
  event: CreateTransitionEvent,
  rootStore: RootStore
): Promise<boolean> => {
  // cascade delete, the database will handle the rest
  // if (event.context.hints?.nested) return true;

  const flowId = event.context.connection?.id;
  if (!flowId) return false;

  const transitionId = flowId;
  const transitionStore = rootStore.transitionStore;

  try {
    const res = await transitionStore.deleteById(transitionId);
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
