import {
  Location,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';

import Databases from '@pages/Database';
import Grid from '@pages/Database/grid';
import { EditorWrapper } from '@pages/Editor/wrapper';
import Home from '@pages/Home/home.page';
import AddNewProcess from '@pages/NewProcess/new-process.page';
import PasswordProtectedPage from '@pages/PasswordProtected/passwordProtected.page';
import GeneralSetting from '@pages/Settings/general-setting';
import Generation from '@pages/Settings/generation';
import GenerationDetails from '@pages/Settings/generation/generationDetails';
import GlobalVariables from '@pages/Settings/global-variables/globalVariable.page';
import SettingNotifications from '@pages/Settings/notifications';
import EditorNotifications from '@pages/Settings/notifications/editor';
import Settings from '@pages/Settings/settings.page';
import TraceInfo from '@pages/Settings/trace-info';
import TraceOverview from '@pages/Settings/trace-overview';

import Layout from '@/layout';
import PageNotFound from '@/pages/PageNotFound/notfound.page';
import { FormPanel } from '@panel/components/action/form/form.panel';
import { FORM_ACTION_PATHS_TO_FC_MAP } from '@panel/components/action/form/form.panel.config';
import { TableEditorConfig } from '@panel/components/action/tableEditor/tableEditor.config';
import { TableEditorPanel } from '@panel/components/action/tableEditor/tableEditor.panel';
import { TableImporterConfig } from '@panel/components/action/tableImporter/tableImporter.config';
import { TableImporterPanel } from '@panel/components/action/tableImporter/tableImporter.panel';
import Transition from '@panel/components/transition';
import { TRANSITION_PATHS_TO_FC_MAP } from '@panel/components/transition/transition.config';
import Panel from '@panel/panel.page';

import { ParamsList } from './routes.types';

export enum RoutePaths {
  Settings = 'settings'
}

const Router = () => {
  const location: Location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/" element={<Layout />} errorElement={<PageNotFound />}>
        <Route index element={<Home />} errorElement={<PageNotFound />} />
        <Route
          path="/new"
          element={<AddNewProcess />}
          errorElement={<PageNotFound />}
        />
        <Route
          path={`/:${ParamsList.ProcessId}`}
          errorElement={
            <PageNotFound
              description="An error has occured"
              title="An error has occured"
              label="take me home"
            />
          }
        >
          <Route path="database" element={<Databases />}>
            <Route path={`:${ParamsList.DatabaseId}`} element={<Grid />} />
          </Route>
          <Route
            path="password-protected"
            element={<PasswordProtectedPage />}
          />
          <Route path={`:${ParamsList.WorkflowId}`}>
            <Route
              path="editor"
              errorElement={
                <PageNotFound
                  description="An error has occured"
                  title="An error has occured"
                  label="take me home"
                />
              }
              element={<EditorWrapper />}
            >
              <Route path="p" element={<Panel />}>
                <Route
                  path={`a/:${ParamsList.ActionId}`}
                  element={<FormPanel />}
                >
                  {Object.keys(FORM_ACTION_PATHS_TO_FC_MAP).map(
                    (actionPath: string) => (
                      <Route
                        key={actionPath}
                        path={actionPath}
                        element={FORM_ACTION_PATHS_TO_FC_MAP[actionPath]}
                      />
                    )
                  )}
                </Route>
                <Route
                  path={`te/:${ParamsList.ActionId}`}
                  element={<TableEditorPanel />}
                >
                  {Object.keys(TableEditorConfig.SUBPATHS_TO_FC_MAP).map(
                    (actionPath: string) => (
                      <Route
                        key={actionPath}
                        path={actionPath}
                        element={
                          TableEditorConfig.SUBPATHS_TO_FC_MAP[
                            actionPath as TableImporterConfig.SubPath
                          ]
                        }
                      />
                    )
                  )}
                </Route>
                <Route
                  path={`ti/:${ParamsList.ActionId}`}
                  element={<TableImporterPanel />}
                >
                  {Object.keys(TableImporterConfig.SUBPATHS_TO_FC_MAP).map(
                    (actionPath) => (
                      <Route
                        key={actionPath}
                        path={actionPath}
                        element={
                          TableImporterConfig.SUBPATHS_TO_FC_MAP[
                            actionPath as TableImporterConfig.SubPath
                          ]
                        }
                      />
                    )
                  )}
                </Route>

                <Route
                  path={`t/:${ParamsList.TransitionId}`}
                  element={<Transition />}
                >
                  {Object.keys(TRANSITION_PATHS_TO_FC_MAP).map(
                    (transitionPath: string) => (
                      <Route
                        key={transitionPath}
                        path={transitionPath}
                        element={TRANSITION_PATHS_TO_FC_MAP[transitionPath]}
                      />
                    )
                  )}
                </Route>
              </Route>
            </Route>
            <Route path={RoutePaths.Settings} element={<Settings />}>
              <Route index element={<Navigate to="general" replace />} />
              <Route path="general" element={<GeneralSetting />} />
              <Route path="generation">
                <Route index element={<Generation />} />
                <Route
                  path={`:${ParamsList.GenerationId}`}
                  element={<GenerationDetails />}
                />
              </Route>
              <Route path="notification">
                <Route index element={<SettingNotifications />} />
                <Route path={`new`} element={<EditorNotifications />} />
                <Route
                  path={`:${ParamsList.NotificationId}`}
                  element={<EditorNotifications />}
                />
              </Route>
              <Route path="trace-info" element={<TraceInfo />} />
              <Route path="trace-overview" element={<TraceOverview />} />
              <Route path="global-variables" element={<GlobalVariables />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
